import { makeStyles, createStyles } from "@material-ui/core/styles";
import colors from "../../common/styles/colors";

const useStyles = makeStyles((theme) =>
  createStyles({
    newAccountWrapper: {
      width: "100%",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignContent: "center",
      },
    },
    newAccountItem: {
      //width: "33%",
      //marginTop: "90px",
      marginBottom: "-6px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        textAlign: "center",
        marginTop: 0,
      },
    },
    registerContainer: {
      width: "45%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        padding: "0 15px",
      },
      backgroundColor: colors.whiteColor,
      marginTop: theme.spacing(10),
    },
    image: {
      width: "100%",
    },
  })
);

export default useStyles;
