import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import useStyles from "./DetectorsNoSearch.style";

const DetectorsNoSearch: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation(["translation"]);

  return (
    <Grid item className={classes.noSearchContainer}>
      <div className={classes.itemContainer}>
        <Typography className={classes.title} component="h3">
          {t("translation:Detectors_nosearch.title")}
        </Typography>
        <Typography className={classes.content}>
          {t("translation:Detectors_nosearch.content")}
        </Typography>
      </div>
      <div className={classes.imgContainer}>
        <img src="/img/Detectortoolportal_width_big.jpg" alt="detectors" />
      </div>
    </Grid>
  );
};

export default DetectorsNoSearch;
