import React from "react";

const InstallerIcon: React.FC = () => {
    return (
        <div style={{ border: "2px solid", marginTop: "70px", width: "80px", height: "80px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img style={{ width: "41px", imageRendering: "-webkit-optimize-contrast" }}
                src="/img/icon_profile_installer_R00.png" />
        </div>
    );
};

export default InstallerIcon;
