import React  from 'react';
import { Route, Redirect, RouteComponentProps, RouteProps } from 'react-router-dom';
import { useAuth } from '../../context/context';
import { AppRoute } from '../../common/constants/routes';
import { useIntl } from 'react-intl';
import { getLocalizedRoute } from '../../common/services/i18n/utils/routeLocalization';

const LoggedOutRoute: React.FC<RouteProps> = ({component: Component, ...rest }) => {
    const authStore = useAuth();
    const { formatMessage, locale } = useIntl();
    const redirectURL = getLocalizedRoute(AppRoute.Home, locale, formatMessage);

    if(!Component) {
        return <Redirect to={ { pathname: redirectURL } } />;
    }

    return(
        <Route
            {...rest}
            render={(props: RouteComponentProps<{}>) =>
                !authStore?.isLoggedIn ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={ { pathname: redirectURL } } />
                )
            }
        />
    );
};

export default LoggedOutRoute;
