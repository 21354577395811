import { makeStyles, createStyles } from "@material-ui/core/styles";
import colors from "../../../common/styles/colors";

const useStyles = makeStyles((theme) =>
  createStyles({
    userName: {
      color: colors.veryLightGreyColor,
      "& img": {
        width: "20px",
        height: "auto",
      },
      "& span": {
        minWidth: "60px",
        paddingLeft: "4px",
        fontSize: "16px",
        fontFamily: "Roboto",
        lineHeight: "18px",
        fontWeight: 500,
        letterSpacing: "-0.04em",
        textAlign: "center",
        textTransform: "none",
      },
    },
    dropdown: {
      top: theme.spacing(8),
      "& .MuiPaper-rounded": {
        borderRadius: "0",
        top: theme.spacing(8),
        boxShadow: "none",
      },
    },
    dropdownItem: {
      backgroundColor: colors.veryLightGreyColor,
      width: "120px",
      padding: "4px 16px",
      color: colors.primaryColor,
      lineHeight: "24px",
      fontSize: "14px",
      fontFamily: "Roboto, sans-serif",
      "&:hover": {
        backgroundColor: colors.lightGreyColor,
      },
      cursor: "default",
    },
    myAccountName: {
      [theme.breakpoints.down("xs")]: {
        display: "none",
      }
    }
  })
);

export default useStyles;
