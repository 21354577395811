import i18n from "i18next";

const isStringNullOrEmpty = (str: any): Boolean => {
    if (typeof str !== 'undefined' && str) {
      return false;
    }
    return true;
  }

export const getDetectorTypeFormatted = (data: any): string => {
    let sensorInfo = data?.sensorInformation;

    let detectorFamily = !isStringNullOrEmpty(sensorInfo?.detectorFamily) ? i18n.t(`translation:BLE_SENSOR_INFORMATION.${sensorInfo.detectorFamily}`) : "";
    let detectorOutputInfo = !isStringNullOrEmpty(sensorInfo?.detectorOutputInfo) ? i18n.t(`translation:BLE_SENSOR_INFORMATION.${sensorInfo.detectorOutputInfo}`) : "";
    let detectorType = !isStringNullOrEmpty(sensorInfo?.detectorType) ? i18n.t(`translation:BLE_SENSOR_INFORMATION.${sensorInfo.detectorType}`) : "";

    let res = "";

    if (!isStringNullOrEmpty(sensorInfo?.detectorShortDescription))
    {
      res += sensorInfo?.detectorShortDescription + ", ";
    }

    if (!isStringNullOrEmpty(detectorFamily))
    {
      res += detectorFamily + ", ";
    }

    if (sensorInfo?.detectorFamily != 'dali2Detector' && !isStringNullOrEmpty(detectorOutputInfo))
    {
      res += detectorOutputInfo + ", ";
    }

    if (!isStringNullOrEmpty(detectorType))
    {
      res += detectorType;
    }

    return res;
};
