export const sortLightSceneGroupsByPercentage = (detector: any): void => {
    if (detector.functions !== undefined) {
        for (let i = 0; i < detector.functions.length; ++i) {
            if (detector.functions[i].type !== undefined
                && detector.functions[i].type.indexOf('lightSceneLuxLevel') !== -1
                && detector.functions[i].value !== undefined) {

                const getPercentageFromGroup = (group: string) => {
                    const words = group.split(/[, ]+/);
                    return words[words.length - 1].replace('%', '');
                }

                const inputArray = detector.functions[i].value;
                const arrayOfGroups = [];
                for (let idx = 0; idx < inputArray.length - 2; idx += 2) {
                    arrayOfGroups.push({
                        group: inputArray[idx],
                        percentage: getPercentageFromGroup(inputArray[idx]),
                        luminaries: inputArray[idx + 1]
                    });
                }

                // sort descending by percentage
                arrayOfGroups.sort(function (groupA: any, groupB: any) {
                    // @ts-ignore
                    return groupB.percentage - groupA.percentage;
                });

                let res = [];
                for (let idx = 0; idx < arrayOfGroups.length; ++idx) {
                    res.push(arrayOfGroups[idx].group);
                    res.push(arrayOfGroups[idx].luminaries);
                }

                res.push(inputArray[inputArray.length - 2]);
                res.push(inputArray[inputArray.length - 1]);

                detector.functions[i].value = res;
            }
        }
    }
}
