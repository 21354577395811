import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import useTypographyStyles from '../../common/styles/typography';
import useStyles from './About.style';
import colors from "../../common/styles/colors";

const About: React.FC = (() => {
    const classes = useStyles();
    const typographyClasses = useTypographyStyles();
    const { t } = useTranslation(['translation']);


    return (
        <Grid container className={classes.aboutContainer}>

            <Grid item className={classes.aboutItem}>
                
                <div className={classes.aboutHeaderImage}>
                    <img src='/img/about_header.jpeg' alt="about" />
                </div>
                <div className={classes.aboutHeaderTextContent}>
                    <Typography className={classes.aboutHeaderTextHeader}>
                        { t('translation:about.title1') }
                    </Typography>
                    <Typography className={classes.aboutHeaderTextParagraph}>
                        { t('translation:about.content1') }
                    </Typography>
                </div>
            </Grid>

            <Grid item className={classes.aboutItem}>
                <div className={classes.card}>
                    <div className={classes.cardContentLightSmall}>
                        <Typography component="h3" className={typographyClasses.headerThreeBlack}>
                            { t('translation:about.title2') }
                        </Typography>
                        <Typography className={typographyClasses.paragraphBlack14px}>
                            { t('translation:about.content2') }
                        </Typography>
                    </div>
                    <div className={classes.cardMedia}>
                        <img src='/img/about1.jpeg' alt="about" />
                    </div>
                </div>
            </Grid>

            <Grid item className={classes.aboutItem}>
                <div className={classes.card}>
                    <div className={classes.cardMediaSmall}>
                        <img src='/img/about2.jpeg' alt="about" />
                    </div>
                    <div className={classes.cardContentLightLarge}>
                        <Typography component="h3" className={typographyClasses.headerThreeBlack}>
                            { t('translation:about.title3') }
                        </Typography>
                        <Typography className={typographyClasses.paragraphBlack14px}>
                            { t('translation:about.content3') }
                        </Typography>
                    </div>
                </div>
            </Grid>

            <Typography component="h2" className={typographyClasses.headerTwoBlack} style={{padding: "15px"}}>
                { t('translation:about.title4') }
            </Typography>
           
            <Grid item className={classes.aboutItem}>
                <div className={classes.card}>
                    <div className={classes.cardContentLightLarge}>
                        <Typography className={classes.aboutSubHeaderTextHeader}>
                            { t('translation:about.title4_1') }
                        </Typography>
                        <Typography className={typographyClasses.paragraphBlack14px} style={{paddingBottom: "20px"}}>
                            { t('translation:about.content4_1') }
                        </Typography>
                        <Typography className={classes.aboutSubHeaderTextHeader}>
                            { t('translation:about.title4_2') }
                        </Typography>
                        <Typography className={typographyClasses.paragraphBlack14px} style={{paddingBottom: "20px"}}>
                            { t('translation:about.content4_2') }
                        </Typography>
                        <Typography className={classes.aboutSubHeaderTextHeader}>
                            { t('translation:about.title4_3') }
                        </Typography>
                        <Typography className={typographyClasses.paragraphBlack14px} style={{paddingBottom: "20px"}}>
                            { t('translation:about.content4_3') }
                        </Typography>
                        <Typography className={classes.aboutSubHeaderTextHeader}>
                            { t('translation:about.title4_4') }
                        </Typography>
                        <Typography className={typographyClasses.paragraphBlack14px} style={{paddingBottom: "20px"}}>
                            { t('translation:about.content4_4') }
                        </Typography>
                    </div>
                    <div className={classes.cardMediaSmall}>
                        <img src='/img/about3.jpeg' alt="about" />
                    </div>
                </div>
            </Grid>

            <Grid item className={classes.aboutItem}>
                <div className={classes.card}>
                    <div className={classes.cardContentLightSmall}>
                        <Typography component="h3" className={typographyClasses.headerThreeBlack}>
                            { t('translation:about.title5') }
                        </Typography>
                        <Typography className={typographyClasses.paragraphBlack14px}>
                            { t('translation:about.content5') }
                        </Typography>
                    </div>
                    <div className={classes.cardMedia}>
                        <img src='/img/about4.jpeg' alt="about" />
                    </div>
                </div>
            </Grid>

        </Grid>
    );
});

export default About;
