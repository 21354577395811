import React from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import { getLocalizedRoute } from "../../../common/services/i18n/utils/routeLocalization";
import { AppRoute } from "../../../common/constants/routes";
import useStyles from "./EmailSentConfirmation.style";

const EmailSentConfirmation: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation(["translation"]);
  const { formatMessage, locale } = useIntl();

  return (
    <Grid container className={classes.statusWrapper}>
      <Grid item className={classes.descriptionContainer}>
        <img
          className={classes.image}
          src="/img/Detectortoolportal_height_small.jpg"
          alt="sentRegisterEmail"
        />
      </Grid>
      <Grid item className={classes.contentWrapper}>
        <img src="/img/mailMessage.svg" alt="message" style={{width: "40px"}}/>
        <Typography className={classes.title} component="h3">
          {t("translation:login_Forgotpassword_Verifyemail.title")}
        </Typography>
        <Typography className={classes.content}>
          {t("translation:login_Forgotpassword_Verifyemail.content")}
        </Typography>
        <Link
          to={getLocalizedRoute(AppRoute.ForgotPassword, locale, formatMessage)}
          className={classes.link}
        >
          {t("translation:common.sendEmailLink")}
        </Link>
      </Grid>
    </Grid>
  );
};

export default EmailSentConfirmation;
