import { makeStyles, createStyles } from "@material-ui/core/styles";
import colors from "../../styles/colors";
import {
  paragraph14pxHumanist,
} from "../../styles/typography";

const useStyles = makeStyles((theme) =>
  createStyles({
    detectorActions: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      padding: "16px 100px 75px",
    },
    infoItem: {
      borderBottom: `0.5px solid ${colors.primaryColor}`,
      '&:last-child': {
        borderBottom: "none"
      },
      padding: "7px 24px",
      cursor: "default",
      "&.MuiListItem-button:hover": {
         backgroundColor: colors.secondaryColor,
      },
    },
    list: {
      width: "100%",
    },
    closeButton: {
      "& button": {
        width: "352px",
      },
      marginTop: theme.spacing(5),
    },
    tabName: {
      padding: "7px 0",
      color: colors.primaryColor,
      ...paragraph14pxHumanist,
    },
    propertyName: {
      padding: "7px 0",
      paddingLeft: "15px",
      color: colors.primaryColor,
      ...paragraph14pxHumanist,
    },
    property: {
      padding: "7px 0",
      paddingLeft: "30px",
      color: "red",
      ...paragraph14pxHumanist,
    },
    newLineProperty: {
      padding: "7px 0",
      paddingLeft: "30px",
      marginTop: "-10px",
      color: "green",
      ...paragraph14pxHumanist,
    },
  })
);

export default useStyles;
