import { makeStyles, createStyles } from "@material-ui/core/styles";
import colors from "../../../common/styles/colors";
import { commonParagraph, mainHeader } from "../../../common/styles/typography";

const inputAlign = {
  paddingLeft: "8px",
  borderLeft: `4px solid transparent`,
  marginLeft: `-12px`,
  minHeight: "100px",
};

const useStyles = makeStyles((theme) =>
  createStyles({
    mainHeader: {
      ...mainHeader,
    },
    registerWrapper: {
      width: "352px",
      [theme.breakpoints.down("md")]: {
        margin: "0 auto",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    registerItem: {
      width: "352px",
      ...inputAlign,
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderWidth: "0",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "0",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    verticalInputSpacing: {
      ...inputAlign,
    },
    lastTextfield: {
      marginBottom: "7px",
      ...inputAlign,
    },
    inputLabel: {
      color: colors.darkerGreyColor,
      ...commonParagraph,
      paddingBottom: "8px",
      paddingTop: "8px",
    },
    textInput: {
      borderRadius: "24px",
      backgroundColor: colors.veryLightGreyColor,
      color: colors.primaryColor,
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          border: "0",
        },
      },
      padding: "10px 24px",
      "& .MuiInputBase-input": {
        fontSize: "16px",
        lineHeight: "18px",
        fontFamily: "Roboto, sans-serif",
        color: colors.primaryColor,
        height: "18px",
        backgroundColor: `${colors.veryLightGreyColor}!important`,
        '&::placeholder': {
          color: "#7F7F7F",
          opacity: 1
        }
      },
      "& .MuiOutlinedInput-input": {
        padding: "5px 0",
      },
      "& .MuiInputBase-root": {
        paddingRight: "0",
      },
    },
    required: {
      color: "red",
    },
    spaceItems: {
      marginLeft: "7px",
    },
    error: {
      color: colors.errorRedColor,
      fontSize: "12px",
      lineHeight: "16px",
      marginTop: "8px",
    },
    errorBorder: {
      borderLeft: `4px solid ${colors.errorRedColor}`,
    },
    recaptcha: {
      marginBottom: theme.spacing(4),
    },
    privacyText: {
      color: colors.primaryColor,
      wordBreak: "break-word",
      lineHeight: "28px",
      fontSize: "16px",
    }
  })
);

export default useStyles;
