import React from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { useAuth } from "../../context/context";
import useStyles from "./HomeLogedIn.style";

const HomeLogedIn: React.FC = () => {
  const classes = useStyles();
  const authStore = useAuth();
  const { t } = useTranslation(["translation"]);

  return (
    <Grid container className={classes.homeLogedInWrapper}>
      <Grid item className={classes.homeLogedInItem}>
        <img src="/img/Detectortoolportal_width_big.jpg" alt="homePage" />
      </Grid>
      <Grid item className={classes.homeLogedInContent}>
        <Typography className={classes.title} component="h1">
          {`${t("translation:HOME-LOGIN.title")} ${
            authStore?.userHistory?.firstName
          }`}
        </Typography>
        <Typography className={classes.paragraph}>
          {t("translation:HOME-LOGIN.aboutProjects")}
        </Typography>
        <Typography className={classes.paragraph}>
          {t("translation:HOME-LOGIN.aboutDetectors")}
        </Typography>
        <Typography className={classes.paragraph}>
          {t("translation:HOME-LOGIN.aboutTemplates")}
        </Typography>
        <List component="nav" className={classes.list}>
          <ListItem>
            <ListItemIcon className={classes.itemList}>
              <img src="/img/Slider_knob.svg" alt="list" />
            </ListItemIcon>
            <ListItemText
              className={classes.listItemText}
              primary={t("translation:HOME-LOGIN.aboutyMTemplates")}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon className={classes.itemList}>
              <img src="/img/Slider_knob.svg" alt="list" />
            </ListItemIcon>
            <ListItemText
              className={classes.listItemText}
              primary={t("translation:HOME-LOGIN.aboutReceivedTemplates")}
            />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
};

export default HomeLogedIn;
