import { makeStyles, createStyles } from '@material-ui/core/styles';
import colors from './common/styles/colors';

const useStyles = makeStyles((theme) => createStyles({
  mainContent: {
    minHeight: '500px',
    backgroundColor: colors.whiteColor,
    position: 'relative',
},
}));

export default useStyles;
