import React from "react";
import { FormControl, Select, MenuItem } from "@material-ui/core";
import ChevronDown from "../ChevronDown";
import useStyles from "./Sort.style";
import { useTranslation } from "react-i18next";

interface IOption {
  name: string;
  key: string;
}

interface ISort {
  sortedBy: string;
  sortingOptions: IOption[];
  handleChangeSelect(event: React.ChangeEvent<{ value: unknown }>): void;
  sortItems(sortBy: string): void;
}

const Sort: React.FC<ISort> = (props) => {
  const classes = useStyles();
  const { sortingOptions, handleChangeSelect, sortedBy, sortItems } = props;
  const { t } = useTranslation(["translation"]);

  return (
    <FormControl variant="outlined">
      <Select
        value={sortedBy}
        onChange={handleChangeSelect}
        className={classes.select}
        IconComponent={ChevronDown}
        MenuProps={{
          classes: { paper: classes.dropdownList },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
      >
        {sortingOptions.map((sort, index) => (
          <MenuItem
            key={index}
            value={sort.key}
            onClick={() => sortItems(sort.key)}
            className={classes.popupSelect}
          >
           {t("translation:common.sortBy")} {sort.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Sort;
