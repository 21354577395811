import { makeStyles, createStyles } from "@material-ui/core/styles";
import colors from "../styles/colors";
import { commonParagraph, commonParagraph14px } from "../styles/typography";

const useStyles = makeStyles((theme) =>
  createStyles({
    modalParagraph: {
      marginBottom: theme.spacing(6),
      color: colors.darkerGreyColor,
      textAlign: "center",
      ...commonParagraph,
    },
    modalParagraphSubTitle: {
      marginBottom: theme.spacing(3),
      color: colors.darkerGreyColor,
      textAlign: "center",
      ...commonParagraph,
    },
    buttonsContainer: {
      display: "flex",
      "& button": {
        width: "160px",
        height: "48px",
      },
      width: "85%",
      justifyContent: "space-around",
    },
    textInput: {
      width: "80%",
      borderRadius: "24px",
      backgroundColor: colors.veryLightGreyColor,
      color: colors.primaryColor,
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          border: "0",
        },
      },
      padding: "10px 24px",
      "& input": {
        color: colors.primaryColor,
        ...commonParagraph14px,
      },
      "& .MuiOutlinedInput-input": {
        padding: "5px 0",
      },
      "& .MuiInputBase-root": {
        paddingRight: "0",
      },
    },
    emailInput: {
      width: "350px",
      marginLeft: "-5px",
      marginBottom: theme.spacing(2),
      display: "flex",
      justifyContent: "space-between",
    },
    bottomButton: {
      "& button": {
          width: "352px",
      },
      marginTop: theme.spacing(3),
    },
    error: {
      color: colors.errorRedColor,
      fontSize: "12px",
      lineHeight: "16px",
      marginTop: "8px",
    },
  })
);

export default useStyles;
