import { makeStyles, createStyles } from "@material-ui/core/styles";
import colors from "./colors";
import { CSSProperties } from "react";

const common: CSSProperties = {
  borderRadius: "24px",
  fontSize: "16px",
  fontFamily: "Roboto, sans-serif",
  lineHeight: "28px",
  padding: "10px 24px",
  textTransform: "none",
};

const common14px: CSSProperties = {
  borderRadius: "24px",
  fontSize: "14px",
  fontFamily: "Roboto, sans-serif",
  lineHeight: "24px",
  padding: "12px 20px",
  textTransform: "none",
};

const commonWithBorder: CSSProperties = {
  borderRadius: "24px",
  fontSize: "16px",
  fontFamily: "Roboto, sans-serif",
  lineHeight: "26px",
  padding: "10px 24px",
  textTransform: "none",
};

export const lightButtonWithBorder: CSSProperties = {
  backgroundColor: colors.whiteColor,
  color: colors.primaryColor,
  border: `1px solid ${colors.darkGreyColor}`,
  borderRadius: "24px",
  fontSize: "16px",
  fontFamily: "Roboto, sans-serif",
  lineHeight: "28px",
  textTransform: "none",
};

const useButtonStyles = makeStyles(() =>
  createStyles({
    primaryButton: {
      backgroundColor: colors.primaryColor,
      color: colors.secondaryColor,
      "&:hover": {
        backgroundColor: colors.primaryColor,
      },
      ...common,
      "&.MuiButton-root.Mui-disabled": {
        backgroundColor: colors.grey,
        color: colors.secondaryColor,
      },
    },
    primaryButtonLargeWidth: {
      backgroundColor: colors.primaryColor,
      color: colors.secondaryColor,
      "&:hover": {
        backgroundColor: colors.primaryColor,
      },
      ...common,
      "&.MuiButton-root.Mui-disabled": {
        backgroundColor: colors.grey,
        color: colors.secondaryColor,
      },
       width: "352px !important"
    },
    primaryButton14px: {
      backgroundColor: colors.primaryColor,
      color: colors.secondaryColor,
      "&:hover": {
        backgroundColor: colors.primaryColor,
      },
      ...common14px,
      "&.MuiButton-root.Mui-disabled": {
        backgroundColor: colors.grey,
        color: colors.secondaryColor,
      },
    },
    inactiveButton: {
      backgroundColor: colors.darkGreyColor,
      color: colors.secondaryColor,
      "&:hover": {
        backgroundColor: colors.darkGreyColor,
      },
      ...common,
      "&.MuiButton-root.Mui-disabled": {
        backgroundColor: colors.grey,
        color: colors.secondaryColor,
      },
    },
    secondaryButton: {
      backgroundColor: colors.veryLightGreyColor,
      border: `1px solid ${colors.darkGreyColor}`,
      color: colors.darkGreyColor,
      ...common,
    },
    lightButton: {
      backgroundColor: colors.whiteColor,
      border: `1px solid ${colors.darkGreyColor}`,
      color: colors.darkGreyColor,
      ...commonWithBorder,
      "&.MuiButton-root:hover": {
        backgroundColor: colors.whiteColor,
      },
    },
    lightButtonLargeWidth: {
      backgroundColor: colors.whiteColor,
      border: `1px solid ${colors.darkGreyColor}`,
      color: colors.darkGreyColor,
      ...commonWithBorder,
      "&.MuiButton-root:hover": {
        backgroundColor: colors.whiteColor,
      },
      width: "352px !important"
    },
    lightButtonNoBorder: {
      backgroundColor: colors.whiteColor,
      color: colors.primaryColor,
      ...common,
      "&:hover": {
        backgroundColor: colors.secondaryColor,
      },
    },
    lightButtonWithBorder: {
      backgroundColor: colors.whiteColor,
      color: colors.darkGreyColor,
      border: `1px solid ${colors.darkGreyColor}`,
      ...common,
      "&:hover": {
        backgroundColor: colors.secondaryColor,
      },
    },
    lightButtonWithBorderLargeWidth: {
      backgroundColor: colors.whiteColor,
      color: colors.darkGreyColor,
      border: `1px solid ${colors.darkGreyColor}`,
      ...common,
      "&:hover": {
        backgroundColor: colors.secondaryColor,
      },
      width: "352px !important"
    },
  })
);

export default useButtonStyles;
