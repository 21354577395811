import { makeStyles, createStyles } from '@material-ui/core/styles';
import colors from '../../common//styles/colors';
import { CSSProperties } from 'react';

const commonContent: CSSProperties  = {
    width: '67%',
    padding: '32px',
}

const useStyles = makeStyles((theme) => createStyles({
    aboutContainer: {
        paddingTop: '45px',
        maxWidth: '1040px',
        margin: '0 auto',
    },
    aboutItem: {
        marginBottom: '30px',
        maxWidth: '1040px'
    },
    card: {
        display: 'flex',
        //maxHeight: '488px',
    },
    cardContentLightSmall: {
        backgroundColor: colors.secondaryColor,
        width: '33%',
        padding: '32px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: '5vh 5vw',
        }
    },
    cardContentLightLarge: {
        backgroundColor: colors.secondaryColor,
        width: '67%',
        padding: '32px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    cardContentBlack: {
        backgroundColor: colors.primaryColor,
        ...commonContent,
    },
    cardContentBlackSmall: {
        width: '33%',
        padding: '32px',
        backgroundColor: colors.primaryColor,
    },
    cardMedia: {
        width: '67%',
        '& img': {
            height: '100%',
            width: '100%',
            objectFit: 'cover'
        },
        [theme.breakpoints.down('sm')]: {
            width: '0',
        }
    },
    cardMediaSmall: {
        width: '33%',
        '& img': {
            height: '100%',
            width: '100%',
            objectFit: 'cover'
        },
        [theme.breakpoints.down('sm')]: {
            width: '0',
        }
    },
    aboutHeaderImage: {
        '& img': {
            width: '100%',
            //maxHeight: '386px',
            maxWidth: '1040px'
        },
    },
    aboutHeaderTextContent: {
        maxHeight: "488px",
        backgroundColor: "black",
        height: "184px",
        marginTop: "-5px",
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
        }
    },
    aboutHeaderTextHeader: {
        fontSize: "16px !important",
        lineHeight: "24px !important",
        fontFamily: "VistaSansReg !important",
        fontWeight: 700,
        paddingBottom: "24px !important",
        color: "#F6F6F6",
        marginLeft: "15px",
        paddingTop: "20px"
    },
    aboutHeaderTextParagraph: {
        fontSize: "14px !important",
        lineHeight: "24px !important",
        fontFamily: "Roboto !important",
        color: "#E5E5E5",
        marginLeft: "15px",
        marginRight: "40px"
    },
    aboutSubHeaderTextHeader: {
        fontSize: "22px !important",
        lineHeight: "26px !important",
        fontFamily: "VistaSansReg !important",
        fontWeight: 700,
        paddingBottom: "5px !important",
        color: colors.primaryColor
    }
}));

export default useStyles;
