import { makeStyles, createStyles } from "@material-ui/core/styles";
import colors from "../../../../common/styles/colors";
import { commonParagraph14px } from "../../../../common/styles/typography";
import { lightButtonWithBorder } from "../../../../common/styles/buttonStyles";

const useStyles = makeStyles((theme) =>
  createStyles({
    closeIcon: {
      paddingBottom: "18px",
      alignSelf: "flex-end",
      cursor: "default",
    },
    templateActions: {
      display: "flex",
      justifyContent: "space-around",
      width: "100%",
      padding: "16px 0 75px",
      "& button": {
        ...lightButtonWithBorder,
        "&.MuiButton-contained:hover": {
          boxShadow: "none",
          backgroundColor: colors.secondaryColor,
        },
      },
    },
    infoItem: {
      borderBottom: `0.5px solid ${colors.primaryColor}`,
      padding: "7px 24px",
      fontSize: "14px",
      cursor: "default",
      "&.MuiListItem-button:hover": {
         backgroundColor: colors.secondaryColor,
      },
    },
    list: {
      width: "100%",
    },
    closeButton: {
      "& button": {
        width: "352px",
      },
      marginTop: theme.spacing(5),
    },
    inputName: {
      marginLeft: "10px",
      padding: "1px 16px",
      borderRadius: "24px",
      backgroundColor: colors.veryLightGreyColor,
      "& input": {
        fontSize: "14px",
        color: colors.primaryColor,
      },
      cursor: "default",
    },
    modalExportCard: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "508px",
      height: "488px",
      paddingTop: theme.spacing(13),
      position: "absolute",
      backgroundColor: colors.secondaryColor,
      alignItems: "center",
    },
    primaryStyle: {
      display: "inline-block",
      fontWeight: 700,
      ...commonParagraph14px,
    },
    secondaryStyle: {
      display: "inline-block",
      color: colors.primaryColor,
    },
  })
);

export default useStyles;
