import React from "react";

const Pencil: React.FC = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0302 34.6649L34.7105 9.98896C35.0703 9.62644 35.6574 9.62644 36.0201 9.98896C36.3825 10.3514 36.3825 10.9362 36.0201 11.2987L11.3424 35.972C11.1599 36.1545 10.9242 36.2445 10.6861 36.2445C10.4481 36.2445 10.2101 36.1519 10.0302 35.972C9.67004 35.6095 9.67004 35.0274 10.0302 34.6649Z"
        fill="#1A1A1A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.2584 1.42674L44.5713 8.73745C45.8096 9.97575 45.8096 11.9893 44.5713 13.2276L15.4727 42.3302C14.798 43.0049 10.1225 44.0686 1.58205 45.4868C1.55007 45.4931 1.51907 45.4967 1.48871 45.4984C1.46868 45.4996 1.44866 45.5 1.42863 45.5C1.18511 45.5 0.947074 45.4021 0.772343 45.2275C0.560793 45.0185 0.462608 44.7142 0.512992 44.4204C1.93635 35.8794 2.99733 31.204 3.66945 30.532L32.771 1.42674C34.0067 0.193731 36.0227 0.188442 37.2584 1.42674ZM14.213 40.9702L43.2642 11.9178C43.7777 11.4019 43.7777 10.5632 43.2642 10.0445L35.9513 2.73384C35.6946 2.47719 35.3561 2.34755 35.0147 2.34755C34.6759 2.34755 34.3374 2.47719 34.0781 2.73647L4.9817 31.8391C4.6035 32.4979 3.48438 38.057 2.55841 43.4441C7.87623 42.5313 13.3639 41.4226 14.213 40.9702Z"
        fill="#1A1A1A"
      />
    </svg>
  );
};

export default Pencil;
