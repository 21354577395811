import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Grid,
    Typography,
    Button,
    InputLabel,
    TextField,
    Box,
} from "@material-ui/core";
import useTypographyStyles from "../../common/styles/typography";
import useButtonStyles from "../../common/styles/buttonStyles";
import useStyles from "./ChangeEmail.style";
import validators from "../../common/utils/validators";

interface IEmail {
    closeModal(): void;
    saveChanges(email: string): void;
    isSuccesful: boolean;
}

const ChangeEmail: React.FC<IEmail> = (props) => {
    const classes = useStyles();
    const typographyClasses = useTypographyStyles();
    const buttonClasses = useButtonStyles();
    const { t } = useTranslation(["translation"]);
    const [email, setEmail] = useState<string>();
    const [isEmailValid, setIsEmailValid] = useState<boolean>(email ? validators.isEmailValid(email) : false);
    const [repeatEmail, setRepeatEmail] = useState<string>();
    const [isRepeatEmailValid, setIsRepeatEmailValid] = useState<boolean>(true);
    const [emailMissingOnSave, setEmailMissingOnSave] = useState<boolean>(false);
    const [repeatEmailMissingOnSave, setRepeatEmailMissingOnSave] = useState<boolean>(false);

    const { closeModal, saveChanges, isSuccesful } = props;

    const handleRepeatEmailChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const repeatEmail = event.target.value;
        if (repeatEmail !== undefined) {
            setRepeatEmail(repeatEmail);
            setIsRepeatEmailValid(repeatEmail === email);
            setRepeatEmailMissingOnSave(false);
        }
    };

    const handleEmailChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const email = event.target.value;
        if (email !== undefined) {
            setEmail(email);
            setIsEmailValid(validators.isEmailValid(email));
            setEmailMissingOnSave(false);

            if (repeatEmail == undefined && email == "") {
                setIsRepeatEmailValid(true);
            }
            else {
                setIsRepeatEmailValid(repeatEmail === email);
            }
        }
    };

    const handleSaveChanges = () => {
        if (!email) {
            setEmailMissingOnSave(true);
        }
        if (!repeatEmail) {
            setRepeatEmailMissingOnSave(true);
        }
        if (email && isEmailValid && isRepeatEmailValid) {
            saveChanges(email);
        }
    };

    return (
        <>
            <Grid item>
                <Typography
                    align="center"
                    component="h3"
                    className={typographyClasses.headerThreeBlack}
                >
                    {t("translation:login_My_account.changeEmailModal.title")}
                </Typography>
            </Grid>
            <Grid item className={classes.form}>
                <div className={`${classes.verticalInputSpacing} ${(emailMissingOnSave || (email && !isEmailValid)) && classes.errorBorder} `}>
                    <InputLabel className={classes.inputLabel} required={true}>
                        {t("translation:login_My_account.changeEmailModal.email")}
                    </InputLabel>
                    <TextField
                        type={"text"}
                        variant="outlined"
                        fullWidth={true}
                        focused={false}
                        placeholder="name@gmail.com"
                        className={classes.textInput}
                        onChange={handleEmailChange}
                    />
                    {
                        (emailMissingOnSave || (email && !isEmailValid)) ? (
                            <Box>
                                <Typography className={classes.error}>
                                    {emailMissingOnSave
                                        ? `${t("translation:common.requiredError")}`
                                        : `${t("translation:common.emailError")}`}
                                </Typography>
                            </Box>
                        ) : ("")
                    }
                </div>
            </Grid>
            <Grid item className={classes.newPassword}>
                <div className={`${classes.verticalInputSpacing} ${(repeatEmailMissingOnSave || (repeatEmail && !isRepeatEmailValid)
                    || (repeatEmail && email && !isEmailValid)) && classes.errorBorder} `}>
                    <InputLabel className={classes.inputLabelAsterisk} required={true}>
                        {t("translation:login_My_account.changeEmailModal.repeatEmail")}
                    </InputLabel>
                    <TextField
                        type={"text"}
                        variant="outlined"
                        fullWidth={true}
                        focused={false}
                        placeholder="name@gmail.com"
                        className={classes.textInput}
                        onChange={handleRepeatEmailChange}
                    />
                    {
                        (!isSuccesful) ? (
                            <Typography className={classes.error}>
                                {
                                    t("translation:common.emailUsedError")
                                }
                            </Typography>
                        ) : (
                            (repeatEmailMissingOnSave || (repeatEmail && !isRepeatEmailValid)) ? (
                                <Box>
                                    <Typography className={classes.error}>
                                        {
                                            repeatEmailMissingOnSave
                                                ? `${t("translation:common.requiredError")}`
                                                : `${t("translation:login_My_account.changeEmailModal.emailsMustMatch")}`
                                        }
                                    </Typography>
                                </Box>
                            ) : (
                                <Box>
                                    <Typography className={classes.error}>
                                        {
                                            (repeatEmail && isRepeatEmailValid && !isEmailValid) && `${t("translation:common.emailError")}`
                                        }
                                    </Typography>
                                </Box>
                            )
                        )
                    }
                </div>
            </Grid>
            <Grid item className={classes.textContainer}>
                <Typography className={classes.modalParagraph}>
                    {t("translation:login_My_account.changeEmailModal.details")}
                </Typography>
            </Grid>
            <Grid item className={classes.buttonsContainer}>
                <Button
                    className={buttonClasses.lightButtonWithBorderLargeWidth}
                    onClick={closeModal}
                >
                    {t("translation:common.cancel")}
                </Button>
            </Grid>
            <Grid item className={classes.buttonsContainer}>
                <Button
                    className={buttonClasses.primaryButtonLargeWidth}
                    onClick={handleSaveChanges}
                    disabled={!isRepeatEmailValid}
                >
                    {t("translation:common.saveChanges")}
                </Button>
            </Grid>
        </>
    );
};

export default ChangeEmail;
