import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Button, Typography, InputLabel, TextField, Box } from "@material-ui/core";
import useStyles from "./InstallerView.style";
import useButtonStyles from "../../common/styles/buttonStyles";
import validators from "../../common/utils/validators";
import { getLocalizedRoute } from "../../common/services/i18n/utils/routeLocalization";
import { AppRoute } from "../../common/constants/routes";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { useAuth } from "../../context/context";
import { getImpersonatedUserData } from "../../data-services/ImpersonateUserData/ImpersonateUserDataService";
import InstallerIcon from "./InstallerIcon";
import InstallerCertifiedIcon from "./InstallerCertifiedIcon";

const InstallerView: React.FC = () => {
    const classes = useStyles();
    const buttonClasses = useButtonStyles();
    const { t } = useTranslation(["translation"]);
    let history = useHistory();
    const { formatMessage, locale } = useIntl();
    const authStore = useAuth();
    const [email, setEmail] = useState("");
    const [isEmailValid, setIsEmailValid] = useState<boolean | undefined>();
    const [isImpersonatedUserCallSuccessful, setIsImpersonatedUserCallSuccessful] = useState<boolean | undefined>(true);

    const installerTitle = t("translation:installerView.welcome").replace('[first name/CS]', '') + authStore?.userHistory.name ?? '';

    const handleEmailChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const email = event.target.value;
        if (email !== undefined) {
            setEmail(email);
            setIsImpersonatedUserCallSuccessful(true);
            setIsEmailValid(validators.isEmailValid(email));
        }
    };

    const activateInstallerView = async () => {
        if (email && isEmailValid) {
            const impersonatedUserData = await getImpersonatedUserData(email);
            if (impersonatedUserData.status === 'success') {
                authStore?.setImpersonatedUserHistory(impersonatedUserData.data);
                authStore?.setImpersonatedUserEmail(email);

                localStorage.setItem("toImpersonateUserEmail", email);

                setIsImpersonatedUserCallSuccessful(true);

                history.push(getLocalizedRoute(AppRoute.InstallerViewLoggedIn, locale, formatMessage));
            } else {
                setIsImpersonatedUserCallSuccessful(false);
            }
        }
    };

    const isFormValid = () => {
        if (isEmailValid === undefined) {
            return true;
        }
        return isEmailValid;
    };

    return (
        <Grid container className={classes.homeContainer}>
            <Grid item className={classes.descriptionContainer}>

                <InstallerCertifiedIcon />

                <p className={classes.title}>
                    {installerTitle}
                </p>

                <p className={classes.description}>
                    {t("translation:installerView.firstDescription")}
                </p>

                <p className={classes.description}>
                    {t("translation:installerView.secondDescription")}
                </p>

                <p className={classes.description}>
                    {t("translation:installerView.thirdDescriptionTitle")}
                    <br></br>
                    {t("translation:installerView.thirdDescriptionFirstItem")}
                    <br></br>
                    {t("translation:installerView.thirdDescriptionSecondItem")}
                    <br></br>
                    {t("translation:installerView.thirdDescriptionThirdItem")}
                </p>

            </Grid>
            <Grid item className={classes.loginContainer}>
                <InstallerIcon />

                <p className={classes.titleRight}>
                    {t("translation:installerView.name")}
                </p>

                <Grid
                    container
                    direction="column"
                    className={classes.formContainer}
                >

                    <Grid item>
                        <div
                            className={`${classes.verticalInputSpacing} ${!isFormValid() && classes.errorBorder
                                } `}
                        >
                            <InputLabel className={classes.inputLabel}>
                                {t("translation:common.emailLabel")}
                            </InputLabel>
                            <TextField
                                type="text"
                                variant="outlined"
                                fullWidth={true}
                                focused={false}
                                placeholder="name@gmail.com"
                                className={classes.textInput}
                                onChange={handleEmailChange}
                            />
                            {
                                (!!email) && !isEmailValid ? (
                                    <Box>
                                        <Typography className={classes.error}>
                                            {email === ""
                                                ? `${t("translation:common.requiredError")}`
                                                : `${t("translation:common.emailError")}`}
                                        </Typography>
                                    </Box>
                                ) : ("")
                            }
                            {
                                !isImpersonatedUserCallSuccessful ? (
                                    <Box>
                                        <Typography className={classes.error}>
                                            {t("translation:installerView.error")}
                                        </Typography>
                                    </Box>
                                ) : ("")
                            }
                        </div>
                    </Grid>

                    <Grid item className={classes.verticalSpacing}>
                        <Button
                            className={buttonClasses.primaryButton}
                            fullWidth={true}
                            onClick={activateInstallerView}
                        >
                            {t("translation:installerView.activateInstallerView")}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    );
};

export default InstallerView;
