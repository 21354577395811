import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Modal } from "@material-ui/core";
import DeleteModal from "../../../common/components/DeleteModal";
import { IProject } from "../../../models/Project";
import CheckAll from "../../../common/components/Actions/CheckAll";
import ExportModal from "../../../common/components/ExportModal";
import Export from "../../../common/components/Actions/Export";
import Delete from "../../../common/components/Actions/Delete";
import Sort from "../../../common/components/Actions/Sort";
import useStyles from "./Actions.style";
import NothingSelectedModal from "../../../common/components/NothingSelectedModal";
import useNothingIsSelectedModalStyles from "../../../common/styles/nothingIsSelectedModalGeneralStyle";

interface IActions {
  allProjectIds: string[];
  project: IProject | undefined;
  projects: IProject[];
  projectNames: string[];
  selectedProjects: string[];
  deleteProjects(projectIds: string[]): void;
  setSelectedProjects(param: string[]): void;
  sortProjects(param: string): void;
  exportProjectsPDF(): void;
  exportProjectsExcel(): void;
  fetchData?(): void;
}

const Actions: React.FC<IActions> = (props) => {
  const classes = useStyles();
  const nothingIsSelectedModalStyles = useNothingIsSelectedModalStyles();
  const { t } = useTranslation(["translation"]);
  const {
    project,
    projects,
    allProjectIds,
    projectNames,
    selectedProjects,
    setSelectedProjects,
    deleteProjects,
    sortProjects,
    exportProjectsPDF,
    exportProjectsExcel,
    fetchData
  } = props;

  const sortingOptions = [
    { name: t('translation:common.sortByProjectName'), key: "name" },
    { name: t('translation:common.sortByCreationDate'), key: "date" },
  ];

  const [sortedBy, setSortedBy] = useState(sortingOptions[0].key);

  const [openExportModal, setOpenExportModal] = useState(false);
  const [openExportNothingIsSelectedModal, setOpenExportNothingIsSelectedModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDeleteNothingIsSelectedModal, setOpenDeleteNothingIsSelectedModal] = useState(false);

  const handleOpenExportModal = () => {
    if (selectedProjects.length > 0) {
      setOpenExportModal(true);
    } else {
      setOpenExportNothingIsSelectedModal(true);
    }
  };

  const handleCloseExportModal = () => {
    setOpenExportModal(false);
    setOpenExportNothingIsSelectedModal(false);
  };

  const handleOpenDeleteModal = () => {
    if (selectedProjects.length > 0) {
      setOpenDeleteModal(true);
    } else {
      setOpenDeleteNothingIsSelectedModal(true);
    }
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setOpenDeleteNothingIsSelectedModal(false);
  };

  const handleChangeSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSortedBy(event.target.value as string);
  };

  return (
    <>
      <Modal open={selectedProjects.length > 0 ? openDeleteModal : openDeleteNothingIsSelectedModal} onClose={handleCloseDeleteModal}>
        <Grid container direction="column" className={openDeleteModal  ? classes.modalDeleteCard : nothingIsSelectedModalStyles.style}>
          {
            openDeleteModal &&
            <DeleteModal
              title={t("translation:Projects_Myprojects.deleteProjects.title")}
              content={t("translation:Projects_Myprojects.deleteProjects.content")}
              deleteButtonName={t("translation:common.delete")}
              remove={() => {
                deleteProjects(selectedProjects);
                handleCloseDeleteModal();
                setSelectedProjects([]);
              }}
              closeModal={handleCloseDeleteModal}
            />
          }
          {
            openDeleteNothingIsSelectedModal &&
            <NothingSelectedModal
                title={t("translation:Projects_Myprojects.deleteProjects.titleNothingIsSelected")}
                content={t("translation:Projects_Myprojects.deleteProjects.contentNothingIsSelected")}
                closeModal={handleCloseDeleteModal}
            />
          }
        </Grid>
      </Modal>
      <Modal open={selectedProjects.length > 0 ? openExportModal : openExportNothingIsSelectedModal} onClose={handleCloseExportModal}>
        <Grid container direction="column" className={openExportModal ? classes.modalExportCard : nothingIsSelectedModalStyles.style}>
          {
            openExportModal &&
            <ExportModal
                title={t("translation:exportModal.projectsTitle")}
                closeModal={handleCloseExportModal}
                exportPDF={exportProjectsPDF}
                exportExcel={exportProjectsExcel}
            />
          }
          {
            openExportNothingIsSelectedModal &&
            <NothingSelectedModal
                title={t("translation:Projects_Myprojects.deleteProjects.titleNothingIsSelected")}
                content={t("translation:Projects_Myprojects.exportProjectsNothingIsSelected")}
                closeModal={handleCloseExportModal}
            />
          }
        </Grid>
      </Modal>
      <Grid container className={classes.actionsBar}>
        <Grid item xs={1}  className={classes.actionsWrapper}>
          <CheckAll
            allItemIds={allProjectIds}
            selectedItemIds={selectedProjects}
            setSelectedItemsIds={setSelectedProjects}
            fetchData={fetchData}
          />
          <Export openExportModal={handleOpenExportModal} />
          <Delete openDeleteModal={handleOpenDeleteModal} />
        </Grid>
        <Grid item>
          <Sort
            sortedBy={sortedBy}
            sortingOptions={sortingOptions}
            handleChangeSelect={handleChangeSelect}
            sortItems={sortProjects}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Actions;
