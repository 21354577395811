import { makeStyles, createStyles } from "@material-ui/core/styles";
import colors from "../../common/styles/colors";
import { commonParagraph } from "../../common/styles/typography";

const useStyles = makeStyles((theme) =>
  createStyles({
    modalParagraph: {
      marginBottom: theme.spacing(6),
      paddingTop: theme.spacing(2.5),
      color: colors.darkerGreyColor,
      ...commonParagraph,
    },
    buttonsContainer: {
      display: "flex",
      "& button": {
        width: "160px",
        height: "48px",
      },
      width: "80%",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2),
    },
    textContainer: {
      display: "flex",
      "& button": {
        width: "160px",
        height: "48px",
      },
      width: "440px",
      justifyContent: "space-between",
    },
    verticalInputSpacing: {
      paddingLeft: "8px",
      borderLeft: `4px solid transparent`,
      marginLeft: `-12px`,
      minHeight: "88px",
    },
    errorBorder: {
      borderLeft: `4px solid ${colors.errorRedColor}`,
    },
    inputLabel: {
      color: colors.darkerGreyColor,
      ...commonParagraph,
      alignSelf: "start",
      "& .MuiInputLabel-asterisk": {
        color: "red",
      },
    },
    textInput: {
      width: "440px",
      borderRadius: "24px",
      backgroundColor: colors.veryLightGreyColor,
      color: colors.primaryColor,
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          border: "0",
        },
      },
      padding: "10px 24px",
      fontSize: "16px",
      lineHeight: "28px",
      fontFamily: "Roboto, sans-serif",
      "& .MuiOutlinedInput-input": {
        padding: "5px 0",
      },
      "& .MuiInputBase-root": {
        paddingRight: "0",
      },
    },
    error: {
      color: colors.errorRedColor,
      fontSize: "12px",
      lineHeight: "16px",
      marginTop: "8px",
    },
    form: {
      paddingTop: theme.spacing(2),
    },
    newPassword: {
      paddingTop: "35px",
      paddingBottom: "40px",
    },
  })
);

export default useStyles;
