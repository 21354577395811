import { ISensorSetting } from "../../models/SensorSetting";
import { IProject } from "../../models/Project";

export interface IProjectResponseModel {
  id: string;
  updatedAt: string;
  createdAt: number;
  userId: string;
  name: string;
  sensorSettings: ISensorSetting[];
  daliCount: number;
  two30Count: number;
}

export const mapFromIProjectResponseModelToIProject = (
  data: IProjectResponseModel
): IProject => ({
  id: data.id,
  userId: data.userId,
  name: data.name,
  sensorSettings: data.sensorSettings,
  createdAt: data.createdAt,
  updatedAt: data.updatedAt,
  daliCount: data.daliCount,
  two30Count: data.two30Count
});
