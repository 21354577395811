import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => createStyles({
    icon: {
        pointerEvents: 'none',
        position: 'absolute',
        paddingRight: '16px',
        right: 0,
    },
}));

const ChevronDown: React.FC = (() => {
    const classes = useStyles();

    return (
        <img src="/img/chevronDown1.svg" alt="dropdown" className={classes.icon} />
    );
});

export default ChevronDown;
