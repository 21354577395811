import { makeStyles, createStyles } from "@material-ui/core/styles";
import colors from "../../../../common/styles/colors";
import { commonParagraph } from "../../../../common/styles/typography";

const useStyles = makeStyles((theme) =>
  createStyles({
    noSearchContainer: {
      display: "flex",
      justifyContent: "space-between",
      paddingTop: theme.spacing(5.6),
    },
    title: {
      textAlign: "center",
      color: colors.primaryColor,
      fontWeight: 700,
      ...commonParagraph,
      marginBottom: theme.spacing(4),
    },
    itemContainer: {
      width: "45%",
      "& img": {
        width: "100%",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    imgContainer: {
      width: "45%",
      "& img": {
        width: "100%",
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      }
    },
    content: {
      color: colors.darkerGreyColor,
      ...commonParagraph,
      textAlign: "center",
      padding: "0 10px",
    },
  })
);

export default useStyles;
