import React from "react";

const InstallerCertifiedIcon: React.FC = () => {
    return (
        <div style={{ border: "2px solid", marginLeft: "70px", marginTop: "70px", width: "80px", height: "80px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img style={{ width: "35px", imageRendering: "-webkit-optimize-contrast" }}
                src="/img/icon_profile_installer_certified_R00.png" />
        </div>
    );
};

export default InstallerCertifiedIcon;
