import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import useStyles from "./Send.style";

interface ISend {
  openSendModal(): void;
  closeModal?(): void;
}

const Send: React.FC<ISend> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation(["translation"]);
  const { openSendModal, closeModal } = props;

  return (
    <Button
      variant="contained"
      className={classes.send}
      startIcon={<img src="/img/mailMessage.svg" alt="mailMessage" />}
      onClick={openSendModal}
    >
      {t("translation:common.send")}
    </Button>
  );
};

export default Send;
