import { useContext, useEffect } from 'react'
import axios from 'axios'
import { AuthContext } from '../context/context'
import { useHistory } from 'react-router-dom'


const ErrorHandler = ({ children }: { children: any }) => {
    const useAuth = useContext(AuthContext)
    const history = useHistory()

    useEffect(() => {
        const responseInterceptor = axios.interceptors.response.use(response => response, async (error) => {
            if (error.response.status === 401) {
                useAuth?.forceDeauthentication()
            }
            return Promise.reject(error)
        })

        return () => {
            axios.interceptors.response.eject(responseInterceptor);
        }
    }, [useAuth])

    return children
}

export default ErrorHandler