import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  InputLabel,
  Modal,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  updateUserData,
  changePassword as changePasswordService,
  changeEmail as changeEmailService
} from "../../data-services/Auth/AuthService";
import { useAuth } from "../../context/context";
import DropDownSelect from "../../common/components/DropDownSelect";
import { IUserUpdate } from "../../models/User";
import SaveChanges from "./SaveChanges";
import StatusModal from "./StatusModal";
import ChangePassword from "./ChangePassword";
import StatusChangePassword from "./StatusChangePassword";
import validators from "../../common/utils/validators";
import useButtonStyles from "../../common/styles/buttonStyles";
import useStyles from "./MyAccount.style";
import i18n from "../../i18n";
import { hasThisCountryWithThisLanguageSpecialLocaleCode } from "../../common/enums/laguages"
import ChangeEmail from "./ChangeEmail";
import { getLocalizedRoute } from "../../common/services/i18n/utils/routeLocalization";
import { AppRoute } from "../../common/constants/routes";
import { useIntl } from "react-intl";
import { ILanguageCountryModel } from "../../models/LanguageCountryModel";
import { languageCountryList } from "../../common/constants/countries";
import { useInterval } from "../../context/interval";

const MyAccount: React.FC = () => {
  let history = useHistory();

  const { formatMessage, locale } = useIntl();
  const { t } = useTranslation(["translation"]);
  const classes = useStyles();
  const buttonStyles = useButtonStyles();
  const authStore = useAuth();
  const userHistory = authStore?.userHistory;
  const [firstName, setFirstName] = useState(userHistory?.firstName);
  const [lastName, setLastName] = useState(userHistory?.lastName);
  const [email, setEmail] = useState<string | null>(userHistory?.email || null);
  const [isFirstNameValid, setIsFirstNameValid] = useState(firstName && firstName?.length > 0);
  const [isLastNameValid, setIsLastNameValid] = useState(lastName && lastName?.length > 0);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(email ? validators.isEmailValid(email) : false);
  const [newLocaleCode, setNewLocaleCode] = useState(locale);
  const [isChangeEmailSuccesful, setIsChangeEmailSuccesful] = useState(true);
  const [changePasswordResponse, setChangePasswordResponse] = useState<number | undefined>(undefined);

  const getLanguageCountryList = (): ILanguageCountryModel[] => {
    return [
      {
        name: t("translation:languages.Select"),
        key: "0",
        languageId: "",
        localeCode: "",
        localCodeGoogle: "en",
        country: "Belgium",
      },
      ...languageCountryList
    ] as ILanguageCountryModel[];
  }

  const languagesAndCountries = getLanguageCountryList();
  let languageCountry = languagesAndCountries.find(c => c.localeCode == authStore?.getCurrentLanguageCode()) ?? languagesAndCountries.find(l => l.localeCode == 'en') as ILanguageCountryModel;
  const [selectedLanguageModel, setSelectedLanguageModel] = useState<ILanguageCountryModel>(languageCountry);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  const [openChangeEmailModal, setOpenChangeEmailModal] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [openChangePasswordStatusModal, setOpenChangePasswordStatusModal,] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);

  useInterval(() => {
    if (selectedLanguageModel.localeCode !== authStore?.getCurrentLanguageCode()) {
      let newLanguageModel = (languagesAndCountries.find(l => l.localeCode == authStore?.getCurrentLanguageCode()) ?? languagesAndCountries.find(l => l.localeCode == 'en')) as ILanguageCountryModel;

      changeLanguage(newLanguageModel)
    }
  }, 300);

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newName = event.target.value;
    if (newName !== undefined) {
      setFirstName(newName);
      setIsFirstNameValid(newName.length > 0);
    }
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newLastName = event.target.value;
    if (newLastName !== undefined) {
      setLastName(newLastName);
      setIsLastNameValid(newLastName.length > 0);
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = event.target.value;
    if (newEmail !== undefined) {
      setEmail(newEmail);
      setIsEmailValid(validators.isEmailValid(newEmail));
    }
  };

  const handleIsFormChangedValidation = () => {
    const formChanged = firstName !== userHistory?.firstName ||
      lastName !== userHistory?.lastName ||
      email !== userHistory?.email ||
      selectedLanguageModel?.country !== userHistory?.country ||
      selectedLanguageModel?.languageId !== userHistory?.language;
    setIsFormChanged(formChanged);
  }

  const handleFormValidation = () => {
    if (isFormChanged) {
      if (
        isFirstNameValid &&
        isLastNameValid &&
        isEmailValid &&
        selectedLanguageModel?.country !== "0" &&
        selectedLanguageModel?.languageId !== "0"
      ) {
        setIsFormValid(true);
        window.addEventListener("beforeunload", (ev) => {
          ev.preventDefault();
          setOpenSaveModal(true);
          return (ev.returnValue = "Close window?");
        });
      } else {
        setIsFormValid(false);
        window.removeEventListener("beforeunload", () => {
          setOpenSaveModal(false);
        });
      }
    }
  };

  const handleOpenSaveModal = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // if (
    //   firstName !== userHistory?.firstName ||
    //   lastName !== userHistory?.lastName ||
    //   email !== userHistory?.email ||
    //   selectedLanguageModel?.country !== userHistory?.country ||
    //   selectedLanguageModel?.languageId !== userHistory.language
    // ) {
    //   updateUser();
    // }
    setOpenSaveModal(true);
  };

  const handleCloseSaveModal = () => {
    setOpenSaveModal(false);  
  };

  const handleOpenPasswordChangeModal = () => {
    setOpenChangePasswordModal(true);
  };

  const handleClosePasswordChangeModal = () => {
    setOpenChangePasswordModal(false);
  };

  const handleOpenEmailChangeModal = () => {
    setOpenChangeEmailModal(true);
  };

  const handleCloseEmailChangeModal = () => {
    setOpenChangeEmailModal(false);
    setIsChangeEmailSuccesful(true);
  };

  const handleCancelSaveModal = () => {
    setOpenSaveModal(false);
  };

  const handleOpenStatusModal = () => {
    setOpenStatusModal(true);
  };

  const handleCloseStatusModal = () => {
    setOpenStatusModal(false);
    //history.goBack();
  };

  const handleOpenChangePasswordStatus = () => {
    setOpenChangePasswordStatusModal(true);
  };

  const handleCloseChangePasswordStatus = () => {
    authStore?.forceDeauthentication();
    history.push(`${getLocalizedRoute(AppRoute.Home, newLocaleCode, formatMessage)}`);
    setOpenChangePasswordStatusModal(false);
  };

  useEffect(() => {
    handleIsFormChangedValidation();
    handleFormValidation();
  });

  const changeLanguage = (language: ILanguageCountryModel | undefined) => {
    language && setSelectedLanguageModel(language);

    if (language && language.localeCode) {
      i18n.changeLanguage(language.localeCode);
      authStore?.updateCurrentLanguageCode(language.localeCode);

      if (language.localCodeGoogle) {
        setSelectedLanguageModel(language);
      }
    }
  }

  const selectCountry = (event: React.ChangeEvent<{ value: any }>) => {
    const countryCode = event.target.value;
    const languageCountry = getLanguageCountryList().find((c) => c.key === countryCode);

    if (languageCountry) {
      changeLanguage(languageCountry);


      // Change url when changing the language and the country in the form
      window.history.replaceState(null, '',
        `${getLocalizedRoute(
          AppRoute.MyAccount,
          languageCountry.localeCode,
          formatMessage
        )}`
      )

      setNewLocaleCode(languageCountry.localeCode);
    }
  };

  const updateUser = async () => {
    try {
      if (
        firstName &&
        lastName &&
        email &&
        selectedLanguageModel?.country !== "0" &&
        selectedLanguageModel?.languageId !== "0"
      ) {
        const user: IUserUpdate = {
          firstName,
          lastName,
          emailAddress: email,
          country: selectedLanguageModel?.country || "",
          language: selectedLanguageModel?.languageId || "",
        };
        const { status } = await updateUserData(user);
        if (status === "success") {

          authStore?.updateUser(user);

          if (selectedLanguageModel?.localeCode) {
            let localeCodeToSet = hasThisCountryWithThisLanguageSpecialLocaleCode(selectedLanguageModel?.country, selectedLanguageModel?.name);
            if (localeCodeToSet === undefined) {
              localeCodeToSet = selectedLanguageModel?.localeCode;
            }
            i18n.changeLanguage(localeCodeToSet);
            authStore?.updateCurrentLanguageCode(localeCodeToSet);
          }
          handleOpenStatusModal();
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const changePassword = async (oldPassword: string, newPassword: string) => {
    try {
      const { status, statusCode } = await changePasswordService({
        oldPassword,
        password: newPassword,
      });
      if (status === "success") {
        handleOpenChangePasswordStatus();
        handleClosePasswordChangeModal();
      }
      else {
        setChangePasswordResponse(undefined);
        setChangePasswordResponse(statusCode);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeEmail = async (newEmail: string) => {
    try {
      const { status } = await changeEmailService({
        email: newEmail,
        browserLanguage: i18n.language
      });
      if (status === "success") {
        handleCloseEmailChangeModal();
        history.push(`${getLocalizedRoute(AppRoute.ChangeEmailStatus, newLocaleCode, formatMessage)}?email=${encodeURIComponent(newEmail)}`);
        setIsChangeEmailSuccesful(true);
      }
      else{
        setIsChangeEmailSuccesful(false);
      }
    } catch (error) {
      
    }
  };

  const resetForm = () => {
    setFirstName(userHistory?.firstName);
    setLastName(userHistory?.lastName);
    setEmail(userHistory?.email || null);

    setSelectedLanguageModel(languageCountry)
  };

  return (
    <>
      <Modal open={openSaveModal} onClose={handleCloseSaveModal}>
        <Grid container direction="column" className={classes.modalCard}>
          <SaveChanges
            cancel={handleCancelSaveModal}
            saveChanges={updateUser}
            closeModal={handleCloseSaveModal}
          />
        </Grid>
      </Modal>
      <Modal open={openStatusModal} onClose={handleCloseStatusModal}>
        <Grid container direction="column" className={classes.modalStatusCard}>
          <StatusModal closeModal={handleCloseStatusModal} />
        </Grid>
      </Modal>
      <Modal open={openChangePasswordStatusModal} onClose={handleCloseChangePasswordStatus}>
        <Grid container direction="column" className={classes.modalStatusCard}>
          <StatusChangePassword closeModal={handleCloseChangePasswordStatus} />
        </Grid>
      </Modal>
      <Modal open={openChangePasswordModal} onClose={handleClosePasswordChangeModal}>
        <Grid
          container
          direction="column"
          className={classes.modalChangePasswordCard}
        >
          <ChangePassword
            saveChanges={changePassword}
            closeModal={handleClosePasswordChangeModal}
            changePasswordResponse={changePasswordResponse}
          />
        </Grid>
      </Modal>
      <Modal open={openChangeEmailModal} onClose={handleCloseEmailChangeModal}>
        <Grid
          container
          direction="column"
          className={classes.modalChangePasswordCard}
        >
          <ChangeEmail
            saveChanges={changeEmail}
            closeModal={handleCloseEmailChangeModal}
            isSuccesful={isChangeEmailSuccesful}
          />
        </Grid>
      </Modal>
      <Grid  container className={classes.newAccountWrapper}>
      <Grid item xs={6}  className={classes.pictureContainer}>
          <img src="/img/Detectortoolportal_height_tall_small.jpg" alt="myAccount" />
        </Grid>
    <div>
      <Grid
        container
        className={classes.myAccountWrapper}
        component="form"
        onSubmit={handleOpenSaveModal}
      >
        <Grid container item className={classes.myAccountForm}>
          <Grid item>
            <Typography
              className={classes.mainHeader}
              component="h1"
              align="center"
            >
              {t("translation:login_My_account.title")}
            </Typography>
          </Grid>
          <Grid item className={classes.inputItem}>
            <div
              className={`${classes.verticalInputSpacing} ${firstName === "" && !isFirstNameValid ? classes.errorBorder : ""
                } `}
            >
              <InputLabel className={classes.inputLabel} required={true}>
                {t("translation:register.firstName")}
              </InputLabel>
              <TextField
                // defaultValue={firstName}
                value={firstName}
                type="text"
                variant="outlined"
                fullWidth={true}
                focused={false}
                placeholder="First Name"
                className={classes.textInput}
                onChange={handleFirstNameChange}
              />
              {firstName === "" && !isFirstNameValid && (
                <Box>
                  <Typography className={classes.error}>
                    {t("translation:common.requiredError")}
                  </Typography>
                </Box>
              )}
            </div>
          </Grid>
          <Grid item className={classes.inputItem}>
            <div
              className={`${classes.verticalInputSpacing} ${lastName === "" && !isLastNameValid ? classes.errorBorder : ""
                } `}
            >
              <InputLabel className={classes.inputLabel} required={true}>
                {t("translation:register.lastName")}
              </InputLabel>
              <TextField
                value={lastName}
                type="text"
                variant="outlined"
                fullWidth={true}
                focused={false}
                placeholder="Last Name"
                className={classes.textInput}
                onChange={handleLastNameChange}
              />
              {lastName === "" && !isLastNameValid && (
                <Box>
                  <Typography className={classes.error}>
                    {t("translation:common.requiredError")}
                  </Typography>
                </Box>
              )}
            </div>
          </Grid>
          <Grid item className={classes.inputItem}>
            <div className={classes.verticalInputSpacing}>
              <div className={classes.labelPasswordOrEmail}>
                <InputLabel className={classes.inputLabel} required={true}>
                  {t("translation:register.email")}
                </InputLabel>
                <InputLabel
                  className={classes.changeLabel}
                  onClick={handleOpenEmailChangeModal}
                  style={{textAlign:"right"}}
                >
                  {t("translation:login_My_account.changeEmail")}
                </InputLabel>
              </div>
              <TextField
                value={email}
                type="email"
                fullWidth={true}
                variant="outlined"
                focused={false}
                placeholder="name@gmail.com"
                className={classes.textInputEmailPassword}
                onChange={handleEmailChange}
              />
              {(!!email || email === "") && !isEmailValid ? (
                <Box>
                  <Typography className={classes.error}>
                    {email === ""
                      ? `${t("translation:common.requiredError")}`
                      : `${t("translation:common.emailError")}`}
                  </Typography>
                </Box>
              ) : (
                ""
              )}
            </div>
          </Grid>
          <Grid item className={classes.inputItem}>
            <div className={classes.verticalInputSpacing}>
              <div className={classes.labelPasswordOrEmail}>
                <InputLabel className={classes.inputLabel} required={true}>
                  {t("translation:common.passwordLabel")}
                </InputLabel>
                <InputLabel
                  className={classes.changeLabel}
                  onClick={handleOpenPasswordChangeModal}
                  style={{textAlign:"right"}}
                >
                  {t("translation:login_My_account.changePassword")}
                </InputLabel>
              </div>
              <TextField
                disabled={true}
                variant="outlined"
                placeholder="********"
                InputProps={{
                  classes: { input: classes.placeholderInput }
                }}
                fullWidth={true}
                className={classes.textInputEmailPassword}
              />
            </div>
          </Grid>
          <Grid item className={classes.inputItem}>
            <InputLabel className={classes.inputLabel}>
              {t("translation:register.country") + '/' + t("translation:register.language")}
            </InputLabel>
            <DropDownSelect
              dropdownType="countries"
              selectOptions={getLanguageCountryList()}
              selectedOption={selectedLanguageModel.key}
              handleChangeSelect={selectCountry}
            />
          </Grid>
          <Grid item>
            <InputLabel className={classes.inputAsterixOnLeftLabel} style={{marginTop: "38px"}} required={true}>
              {t("translation:common.requiredFields")}
            </InputLabel>
          </Grid>
          <Grid item className={classes.buttonsItem} style={{marginTop: "32px"}}>
            <Button
              className={` ${buttonStyles.lightButtonLargeWidth} ${classes.width}`}
              onClick={resetForm}
            >
              {t("translation:common.cancel")}
            </Button>
          </Grid>
          <Grid item className={classes.buttonsItem} style={{marginTop: "16px"}}>
            <Button
              disabled={!isFormValid || !isFormChanged}
              className={buttonStyles.primaryButtonLargeWidth}
              type="submit"
            >
              {t("translation:common.saveChanges")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      </div>
    </Grid>
    </>
  );
};

export default MyAccount;
