import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Modal,
} from "@material-ui/core";
import Export from "../../../../common/components/Actions/Export";
import ExportModal from "../../../../common/components/ExportModal";
import Send from "../../../../common/components/Actions/Send";
import { IReceivedTemplateModel } from "../../../../models/TemplatesModel";
import SensorDataComponent from "../../../../common/components/SensorDataComponent/SensorDataComponent";
import { exportDetectorsPDF } from "../../../../common/components/Export/ExportPDF";
import { exportDetectorsExcel } from "../../../../common/components/Export/ExportExcel";
import useTypographyStyles from "../../../../common/styles/typography";
import useButtonStyles from "../../../../common/styles/buttonStyles";
import useStyles from "./ReceivedTemplatesDetails.style";
import { getDetectorTypeFormatted } from "../../../../data-services/Templates/TemplateMapper";
import { useAuth } from "../../../../context/context";

interface IReceivedTemplatesDetails {
  receivedTemplate: IReceivedTemplateModel;
  closeModal(): void;
  openSendModal(): void;
}

const ReceivedTemplatesDetails: React.FC<IReceivedTemplatesDetails> = (
  props
) => {
  const classes = useStyles();
  const typoClasses = useTypographyStyles();
  const buttonStyles = useButtonStyles();
  const { t } = useTranslation(["translation"]);
  const timeZone = moment.tz.guess();
  const [openExportModal, setOpenExportModal] = useState(false);
  const authStore = useAuth();
  const { receivedTemplate, closeModal, openSendModal } = props;

  const contactDetails = t("translation:SensorData.export.contactDetails")
    + ' ' + authStore?.userHistory?.firstName + ', '
    + authStore?.userHistory?.lastName + ', '
    + authStore?.userHistory?.email;

  const handleOpenExportModal = () => {
    setOpenExportModal(true);
  };

  const handleCloseExportModal = () => {
    //setOpenExportModal(false);
    closeModal();
  };

  const exportPDF = () => {
    try {
      exportDetectorsPDF([receivedTemplate], false, contactDetails);
    } catch (e) {
      console.log(e);
    }
  };

  const exportExcel = () => {
    try {
      exportDetectorsExcel([receivedTemplate], false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
    {openExportModal ? (
      <Grid container direction="column" className={classes.modalExportCard}>
        <ExportModal
          title={t("translation:exportModal.templateTitle")}
          closeModal={handleCloseExportModal}
          exportPDF={exportPDF}
          exportExcel={exportExcel}
        />
      </Grid>
    ) : (
      <>
        <Grid item className={classes.closeIcon} onClick={closeModal}>
          <img src="/img/close.svg" alt="close" />
        </Grid>
        <Grid item>
          <Typography className={typoClasses.headerThreeBlack} component="h3">
            {!!receivedTemplate.templateName
              ? receivedTemplate.templateName
              : `${receivedTemplate.sensorInformation.name}
                ${t(
                `translation:BLE_SENSOR_INFORMATION.${receivedTemplate.sensorInformation.detectorFamily}`
              )}
                ${t(
                `translation:BLE_SENSOR_INFORMATION.${receivedTemplate.detectorType}`
              )}`}
          </Typography>
        </Grid>
        <Grid item className={classes.templateActions}>
          <Send openSendModal={openSendModal} closeModal={closeModal} />
          <Export
            openExportModal={handleOpenExportModal}
            closeModal={closeModal}
          />
        </Grid>
        <Grid item className={classes.list}>
          <List component="nav" aria-labelledby="nested-list-subheader">
            <ListItem button disableRipple={true} className={classes.infoItem}>
              <ListItemText
                classes={{
                  primary: typoClasses.modalLabel,
                  secondary: typoClasses.modalContent,
                }}
                primary={`${t(
                  "translation:Templates_Mytemplates.details.templateName"
                )}: \u00A0`}
                secondary={`
            ${!!receivedTemplate.templateName
                    ? receivedTemplate.templateName
                    : `${receivedTemplate.sensorInformation.name}
                ${t(
                      `translation:BLE_SENSOR_INFORMATION.${receivedTemplate.sensorInformation.detectorFamily}`
                    )}
                ${t(
                      `translation:BLE_SENSOR_INFORMATION.${receivedTemplate.detectorType}`
                    )}`
                  }
            `}
              />
            </ListItem>
            <ListItem button disableRipple={true} className={classes.infoItem}>
              <ListItemText
                classes={{
                  primary: typoClasses.modalLabel,
                  secondary: typoClasses.modalContent,
                }}
                primary={`${t(
                  "translation:Templates_Mytemplates.details.detectorType"
                )}: \u00A0`}
                secondary={getDetectorTypeFormatted(receivedTemplate, t)}
              />
            </ListItem>
            <ListItem button disableRipple={true} className={classes.infoItem}>
              <ListItemText
                classes={{
                  primary: typoClasses.modalLabel,
                  secondary: typoClasses.modalContent,
                }}
                primary={t("translation:Templates_Receivedtemplates.tableHeadings.receivedFrom")}
                secondary={`${receivedTemplate.receivedFrom}`}
              />
            </ListItem>
            <ListItem button disableRipple={true} className={classes.infoItem}>
              <ListItemText
                classes={{
                  primary: typoClasses.modalLabel,
                  secondary: typoClasses.modalContent,
                }}
                primary={`${t(
                  "translation:Templates_Mytemplates.details.lastUpload"
                )}: \u00A0`}
                secondary={` ${moment
                  .tz(receivedTemplate.uploadedAt, timeZone)
                  .format("DD.MM.YYYY")} `}
              />
            </ListItem>

            <SensorDataComponent detector={receivedTemplate} isSensorSetting={false} />
          </List>
        </Grid>
        <Grid item className={classes.closeButton}>
          <Button className={buttonStyles.primaryButton} onClick={closeModal}>
            {t("translation:common.closeButton")}
          </Button>
        </Grid>
      </>
    )}
    </>
  );
};

export default ReceivedTemplatesDetails;
