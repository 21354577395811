import React, { useState, useEffect } from "react";
import { Grid, ListItem, ListItemText, Typography } from "@material-ui/core";
import GetSensorDescription from "../SensorData/GetSensorDescription";
import useTypographyStyles from "../../styles/typography";
import useStyles from "./SensorDataComponent.style";

const SensorDataComponent: React.FC<any> = (props) => {
  const classes = useStyles();
  const typoClasses = useTypographyStyles();
  const [sensorData, setSensorData] = useState<any>();
  let isSensorSetting = (props.isSensorSetting == false) ? false : true;
  //console.log("===", props.isSensorSetting)
  useEffect(() => {
    let SensorDescription = GetSensorDescription(props.detector, isSensorSetting);
    setSensorData(SensorDescription);
  }, []);

  return (
    <>
      {sensorData === undefined ? (
        <div> {"Undefined"} </div>
      ) : (
        <Grid item className={classes.list}>
          {Object.entries(sensorData)
            .filter((tab) => tab[0] !== "tabs")
            .map((tab: any, i: number) => {
              const settings = tab[1];

              // if nothing in this tab move fwd
              if (settings.length == 0) return;

              return (
                <>
                  <ListItem button disableRipple={true} className={classes.infoItem}>
                    <ListItemText
                      classes={{
                        primary: typoClasses.modalLabel,
                      }}
                      primary={`${Object.entries(sensorData.tabs)[i][1]}`}
                    />
                  </ListItem>
                  {settings.map((el: any, index: any) => {
                    return (
                      <ListItem button disableRipple={true} className={classes.infoItem} key={index}>
                        <ListItemText
                          classes={{
                            primary: typoClasses.modalLabel,
                            secondary: typoClasses.modalContent,
                          }}
                          primary={el.label}
                          secondary= {Array.isArray(el.value) ? el.value.join(', ') : el.value}
                        />
                      </ListItem>
                    );
                  })}
                </>
              );

              /* return (
                <>
                  <ListItem button className={classes.infoItem}>
                    <ListItemText
                      classes={{
                        primary: typoClasses.modalLabel,
                      }}
                      primary={`${Object.entries(sensorData.tabs)[i][1]}`}
                    />
                  </ListItem>
                  {tab[1].map((tabProps: any) => {
                    console.log(tabProps);

                    return (
                      <>
                        {Object.entries(tabProps).map((property: any) => {
                          if (property[1].includes(",")) {
                            return (
                              <ListItem
                                button
                                className={classes.infoItem}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <ListItemText
                                  classes={{
                                    primary: typoClasses.modalLabel,
                                    secondary: typoClasses.modalContent,
                                  }}
                                  style={{ width: "100%" }}
                                  primary={`${property[1].split(":")[0]}:`}
                                  secondary={
                                    <p>
                                      {`${property[1].split(":")[1]}`}
                                      {property[1].split(":")[2] && (
                                        <span>
                                          <br />
                                          {`${property[1].split(":")[2]}`}
                                        </span>
                                      )}
                                      {property[1].split(":")[3] && (
                                        <span>
                                          <br />
                                          {`${property[1].split(":")[3]}`}
                                        </span>
                                      )}
                                      {property[1].split(":")[4] && (
                                        <span>
                                          <br />
                                          {`${property[1].split(":")[4]}`}
                                        </span>
                                      )}
                                    </p>
                                  }
                                />
                              </ListItem>
                            );
                          } else if (property[0] === "name") {
                            if (property[0].includes("notUsed")) {
                              return;
                            }
                            return property[1].includes(":") ? (
                              <ListItem button className={classes.infoItem}>
                                <ListItemText
                                  classes={{
                                    primary: typoClasses.modalLabel,
                                    secondary: typoClasses.modalContent,
                                  }}
                                  primary={`${property[1].split(":")[0]}:`}
                                  secondary={`${property[1].split(":")[1]}`}
                                />
                              </ListItem>
                            ) : (
                              <ListItem button className={classes.infoItem}>
                                <ListItemText
                                  classes={{
                                    primary: typoClasses.modalLabel,
                                  }}
                                  primary={`${property[1]}`}
                                />
                              </ListItem>
                            );
                          } else {
                            if (property[1].includes("notUsed")) {
                              return;
                            }
                            return property[1].includes(":") ? (
                              <ListItem button className={classes.infoItem}>
                                <ListItemText
                                  classes={{
                                    primary: typoClasses.modalLabel,
                                    secondary: typoClasses.modalContent,
                                  }}
                                  primary={`${property[1].split(":")[0]}:`}
                                  secondary={
                                    <p>
                                      {`${property[1].split(":")[1]}`}
                                      {property[1].split(":")[2] && (
                                        <span>
                                          <br />
                                          {`${property[1].split(":")[2]}`}
                                        </span>
                                      )}
                                    </p>
                                  } //wiredButton values
                                />
                              </ListItem>
                            ) : (
                              <ListItem button className={classes.infoItem}>
                                <ListItemText
                                  classes={{
                                    secondary: typoClasses.modalContent,
                                  }}
                                  secondary={`${property[1]}`}
                                />
                              </ListItem>
                            );
                          }
                        })}
                      </>
                    );
                  })}
                </>
              ); */
            })}
        </Grid>
      )}
    </>
  );
};

export default SensorDataComponent;
