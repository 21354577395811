import httpService from "../HTTPService";
import { IDeleteResponse } from "../../models/ResponseModel";

const baseUrl = "SensorSetting";

export const removeSensorSettings = async (
  sensorSettingIds: string[]
): Promise<IDeleteResponse> => {
  try {
    const url = baseUrl + `/DeleteMany`;
    const response = await httpService.sendMessage(
      "post",
      url,
      sensorSettingIds
    );
    if (response.status === 200) {
      return { status: "success" };
    }
    return { status: "error" };
  } catch (error) {
    return { status: "error" };
  }
};
