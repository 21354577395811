import React from 'react';
import { IntlProvider } from 'react-intl';
import { Route, Redirect } from 'react-router-dom';
import { languageList } from '../../../constants/countries';
import { ILang, LangObjectName } from '../../../enums/laguages';
import { LanguageStrings } from '../localizations';

interface Props {
  RouterComponent: React.ComponentClass<any>;
  languages: { [k: number]: string };
  appStrings: { [prop: string]: LanguageStrings };
  defaultLanguage?: ILang;
}

export const LocalizedRouter: React.FC<Props> = ({
  children,
  RouterComponent,
  appStrings,
}) => (
  <RouterComponent>
    <Route path="/:lang">
      {({ location }) => {
        const currentPath = window.location.pathname;
        let cPathEnding = localStorage.getItem("currentPathEnding");
        if (cPathEnding != null) {
          localStorage.setItem("previousPathEnding", cPathEnding);
        }

        const currentPathEnding = currentPath.split('/')[currentPath.split('/').length-1]
        localStorage.setItem("currentPathEnding", currentPathEnding);

        /**
         * If language is not in route path, redirect to language root
         */
        let pathNameParts = location.pathname.split('/');
        let pathNameLanguage = pathNameParts[1];
        let currentLanguage = localStorage.getItem("currentLanguageCode");

        if (!currentLanguage) {
          pathNameLanguage = languageList.find(l => l.localeCode.toLowerCase() === pathNameLanguage.toLowerCase())?.localeCode || "en";
          localStorage.setItem("currentLanguageCode", pathNameLanguage);
          currentLanguage = pathNameLanguage;
        }
        else if (currentLanguage.toLowerCase() != pathNameLanguage.toLowerCase()) {
          pathNameParts[1] = currentLanguage;
          return <Redirect to={pathNameParts.join('/')} />;
        }

        const langObject = LangObjectName[currentLanguage];

        const { pathname } = location;
        if (!pathname.includes(`/${currentLanguage}/`)) {
          return <Redirect to={`/${currentLanguage}/home`} />;
        }

        /**
         * Return Intl provider with default language set
         */
        return (
          <IntlProvider locale={pathNameLanguage} messages={appStrings[langObject]}>
            {children}
          </IntlProvider>
        );
      }}
    </Route>
  </RouterComponent>
);
