import React from 'react';

const UncheckedGreyBox: React.FC = (() => {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1.25781" y="1" width="18" height="18" rx="1" stroke="#999999" strokeWidth="2"/>
        </svg>
    );
});

export default UncheckedGreyBox;
