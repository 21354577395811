import httpService from "../HTTPService";
import {
  ITemplateResponse,
  ITemplatesResponse,
  IReceivedTemplateResponse,
  IReceivedTemplatesResponse,
  IDeleteResponse,
  INikoLibraryResponse,
  INewMyTemplatesCountResponse,
  INewReceivedTemplatesCountResponse,
} from "../../models/ResponseModel";
import {
  mapFromTemplateResponseModelToITemplateModel,
  mapReceivedTemplates,
  ITemplateResponseModel,
  IReceivedTemplateResponseModel,
  mapNikoLibraryModel,
  INikoLibraryResponseModel,
} from "./TemplateMapper";
import { stringify } from "querystring";

const baseUrl = "Template";

export const getTemplateById = async (templateId: string, isReceived: boolean = false,impersonatedUserEmail: string | null): Promise<any> => {
  try {
    let initialUrl = baseUrl + `/GetTemplateById/${templateId}`
    let url = impersonateIfNeeded(initialUrl, impersonatedUserEmail);

    if (initialUrl != url) {
      url += `&isReceived=${isReceived}`;
    }
    else {
      url += `?isReceived=${isReceived}`;
    }

    const response = await httpService.sendMessage("get", url);
    if (response.status === 200) {
      let d = mapFromTemplateResponseModelToITemplateModel(response.data);

      return {
        status: "success",
        data: isReceived ? mapReceivedTemplates(response.data) : d
      };
    } else if (response.status === 401) {
      return { status: "error", message: "Test error not auth" };
    } else {
      return { status: "error", message: "Test error bad request" };
    }
  } catch (error) {
    return { status: "error" };
  }
};

export const getTemplates = async (impersonatedUserEmail: string | null,
                                  withFullInfo: boolean = false,
                                  sortBy: string = 'updatedAt',
                                  offset: number = 0,
                                  limit: number = 10000): Promise<ITemplatesResponse> => {
  try {
    let url = impersonateIfNeeded(baseUrl, impersonatedUserEmail);

    if (baseUrl != url) {
      url += `&withFullInfo=${withFullInfo}`;
    }
    else {
      url += `?withFullInfo=${withFullInfo}`;
    }
    url += `&sortBy=${sortBy}`;
    url += `&offset=${offset}`;
    url += `&limit=${limit}`;

    const response = await httpService.sendMessage("get", url);
    if (response.status === 200) {
      return {
        status: "success",
        data: response.data.map((t: ITemplateResponseModel) =>
          mapFromTemplateResponseModelToITemplateModel(t)
        ),
      };
    } else if (response.status === 401) {
      return { status: "error", message: "Test error not auth" };
    } else {
      return { status: "error", message: "Test error bad request" };
    }
  } catch (error) {
    return { status: "error" };
  }
};

export const getNewMyTemplatesCount = async (lastMyTemplatesSeen: number, impersonatedUserEmail: string | null): Promise<INewMyTemplatesCountResponse> => {
  if (lastMyTemplatesSeen == undefined) return { status: "error", message: "Invalid timestamp" };
  try {
    let url = baseUrl + `/NewMyTemplatesCount?lastSeen=${lastMyTemplatesSeen}`;
    url = impersonateIfNeeded(url, impersonatedUserEmail);
    const response = await httpService.sendMessage("get", url);
    if (response.status === 200) {
      return {
         status: "success",
         data: response.data.count
      };
    } else if (response.status === 401) {
      return { status: "error", message: "Test error not auth" };
    } else {
      return { status: "error", message: "Test error bad request" };
    }
  } catch (error) {
    return { status: "error" };
  }
};

export const getReceivedMyTemplatesCount = async (lastReceivedTemplatesSeen: number, impersonatedUserEmail: string | null): Promise<INewReceivedTemplatesCountResponse> => {
  if (lastReceivedTemplatesSeen == undefined) return { status: "error", message: "Invalid timestamp" };
  try {
    let url = baseUrl + `/NewReceivedTemplatesCount?lastSeen=${lastReceivedTemplatesSeen}`;
    url = impersonateIfNeeded(url, impersonatedUserEmail);
    const response = await httpService.sendMessage("get", url);
    if (response.status === 200) {
      return {
         status: "success",
         data: response.data.count
      };
    } else if (response.status === 401) {
      return { status: "error", message: "Test error not auth" };
    } else {
      return { status: "error", message: "Test error bad request" };
    }
  } catch (error) {
    return { status: "error" };
  }
};


export const updateTemplate = async (data: { name: string; id: string; }, impersonatedUserEmail: string | null): Promise<any> => {
  try {
    let url = impersonateIfNeeded(baseUrl, impersonatedUserEmail);
    const response = await httpService.sendMessage("put", url, data);
    if (response.status === 200) {
      return { status: "success" };
    } else if (response.status === 401) {
      return { status: "error", message: "Test error not auth" };
    } else {
      return { status: "error", message: "Test error bad request" };
    }
  } catch (error) {
    return "error";
  }
};

export const getReceivedTemplates = async (impersonatedUserEmail: string | null,
                                          withFullInfo: boolean = false,
                                          sortBy: string = 'updatedAt',
                                          offset: number = 0,
                                          limit: number =  10000): Promise<IReceivedTemplatesResponse> => {
  let url = baseUrl + `/Received`;
  url = impersonateIfNeeded(url, impersonatedUserEmail);

  if (url.includes('?')) {
    url += `&withFullInfo=${withFullInfo}`;
  }
  else {
    url += `?withFullInfo=${withFullInfo}`;
  }
  url += `&sortBy=${sortBy}`;
  url += `&offset=${offset}`;
  url += `&limit=${limit}`;

  try {
    const response = await httpService.sendMessage("get", url);
    if (response.status === 200) {
      return {
        status: "success",
        data: response.data.map((t: IReceivedTemplateResponseModel) =>
          mapReceivedTemplates(t)
        ),
      };
    } else if (response.status === 401) {
      return { status: "error", message: "Test error not auth" };
    } else {
      return { status: "error", message: "Test error bad request" };
    }
  } catch (error) {
    return { status: "error" };
  }
};

export const removeReceivedTemplate = async (sensorSettingIds: string[], impersonatedUserEmail: string | null): Promise<IDeleteResponse> => {
  let url = baseUrl + `/DeleteReceived`;
  url = impersonateIfNeeded(url, impersonatedUserEmail);
  try {
    const response = await httpService.sendMessage(
      "post",
      url,
      sensorSettingIds
    );

    if (response.status === 204) {
      return { status: "success" };
    } else if (response.status === 401) {
      return { status: "error", message: "Test error not auth" };
    } else if (response.status === 404) {
      return { status: "error", message: "Not found test" };
    } else {
      return { status: "error", message: "Test error bad request" };
    }
  } catch (error) {
    return { status: "error" };
  }
};

export const removeTemplate = async (sensorSettingId: string, impersonatedUserEmail: string | null): Promise<IDeleteResponse> => {
  let url = baseUrl + `/UnMarkAsFavorite/${sensorSettingId}`;
  url = impersonateIfNeeded(url, impersonatedUserEmail);

  try {
    const response = await httpService.sendMessage(
      "delete",
      url,
      sensorSettingId
    );

    if (response.status === 204) {
      return { status: "success" };
    } else if (response.status === 401) {
      return { status: "error", message: "Test error not auth" };
    } else if (response.status === 404) {
      return { status: "error", message: "Not found test" };
    } else {
      return { status: "error", message: "Test error bad request" };
    }
  } catch (error) {
    return { status: "error" };
  }
};

export const getNikoLibrarySensorSettings = async (impersonatedUserEmail: string | null): Promise<INikoLibraryResponse> => {
  let url = "Library";
  url = impersonateIfNeeded(url, impersonatedUserEmail);

  try {
    const response = await httpService.sendMessage("get", url);
    if (response.status === 200) {
      return {
        status: "success",
        data: response.data.map((nl: INikoLibraryResponseModel) =>
          mapNikoLibraryModel(nl)
        ),
      };
    } else if (response.status === 401) {
      return { status: "error", message: "Test error not auth" };
    } else {
      return { status: "error", message: "Test error bad request" };
    }
  } catch (error) {
    return { status: "error" };
  }
};

const impersonateIfNeeded = (initialUrl: string, impersonatedUserEmail: string | null): string =>  {
  let url = initialUrl;
  if (impersonatedUserEmail !== null) {
    if (url.includes('?')) {
      url += ("&impersonate=" + impersonatedUserEmail);
    }
    else {
      url += ("?impersonate=" + impersonatedUserEmail);
    }
  }
  return url;
}
