import React from "react";

interface ShowPasswordProps {
    showPassword: boolean,
}

const ShowPassword: React.FC<ShowPasswordProps> = (props: ShowPasswordProps) => {
    return (
        <img
            style={{ imageRendering: "-webkit-optimize-contrast", width: "24px" }}
            src={`/img/${props.showPassword ? "icon_camera_peek_view_disabled_R00.png" : "icon_camera_peek_view_R00.png"}`}
            alt="seePassword"
        />
    );
};

export default ShowPassword;
