import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Button } from "@material-ui/core";
import useTypographyStyles from "../../../common/styles/typography";
import useButtonStyles from "../../../common/styles/buttonStyles";
import useStyles from "./SaveChanges.style";

interface ISaveChanges {
  cancel(): void;
  closeModal(): void;
  saveNewName(): void;
}

const SaveChanges: React.FC<ISaveChanges> = (props) => {
  const classes = useStyles();
  const typographyClasses = useTypographyStyles();
  const buttonClasses = useButtonStyles();
  const { t } = useTranslation(["translation"]);

  const { saveNewName, closeModal, cancel } = props;

  return (
    <>
      <Grid item>
        <Typography
          align="center"
          component="h3"
          className={typographyClasses.headerThreeBlack}
        >
          {t("translation:Projects_View.saveModal.title")}
        </Typography>
        <Typography className={classes.modalParagraph}>
          {t("translation:Projects_View.saveModal.content")}
        </Typography>
      </Grid>
      <Grid item className={classes.buttonsContainer}>
        <Button
          className={`${buttonClasses.secondaryButton} ${classes.lightButtonColor}`}
          onClick={() => cancel()}
        >
          {t("translation:common.cancel")}
        </Button>
        <Button
          className={buttonClasses.primaryButton}
          onClick={() => {
            saveNewName();
            closeModal();
          }}
        >
          {t("translation:common.save")}
        </Button>
      </Grid>
    </>
  );
};

export default SaveChanges;
