export interface ILang {
  [index: string]: string;
}

export interface ICountry {
  [index: string]: string;
}

export const AppLanguage: ILang = {
  Belgium_NL: 'be-NL',
  Belgium_FR: 'be-FR',
  Danish: 'da-DK',
  German: 'de-DE',
  French: 'fr-BE',
  English: 'en',
  Netherlands: 'nl-BE',
  Norway: 'nb-NO',
  Austria: 'at-DE',
  Poland: 'pl-PL',
  Switzerland_DE: 'sz-DE',
  Slovakia: 'sk-SK',
  Switzerland_FR: 'sz-FR',
  Sweden: 'sv-SE',
  Switzerland_IT: 'it-CH'
}

export const LangObjectName: ICountry = {
  [AppLanguage.English]: 'en',
  [AppLanguage.French]: 'fr_BE',
  [AppLanguage.Danish]: 'da_DK',
  [AppLanguage.German]: 'de_DE',
  [AppLanguage.Sweden]: 'sv_SE',
  [AppLanguage.Netherlands]: 'nl_BE',
  [AppLanguage.Norway]: 'nb_NO',
  [AppLanguage.Poland]: 'pl_PL',
  [AppLanguage.Slovakia]: 'sk_SK',
  [AppLanguage.Austria]: 'at_DE',
  [AppLanguage.Switzerland_FR]: 'sz_FR',
  [AppLanguage.Switzerland_DE]: 'sz_DE',
  [AppLanguage.Switzerland_IT]: 'it_CH',
  [AppLanguage.Belgium_FR]: 'be_FR',
  [AppLanguage.Belgium_NL]: 'be_NL',
}

export const AppCountries: ICountry = {
  [AppLanguage.English]: 'International (EN)',
  [AppLanguage.French]: 'France',
  [AppLanguage.Danish]: 'Danmark',
  [AppLanguage.German]: 'Deutschland',
  [AppLanguage.Sweden]: 'Sverige',
  [AppLanguage.Netherlands]: 'Nederland',
  [AppLanguage.Norway]: 'Norway',
  [AppLanguage.Poland]: 'Polski',
  [AppLanguage.Slovakia]: 'Slovensko',
  [AppLanguage.Austria]: 'Österreich',
  [AppLanguage.Switzerland_FR]: 'Suisse',
  [AppLanguage.Switzerland_DE]: 'Schweiz',
  [AppLanguage.Switzerland_IT]: 'Svizzera',
  [AppLanguage.Belgium_FR]: 'Belgique',
  [AppLanguage.Belgium_NL]: 'België',
}

export const hasThisCountryWithThisLanguageSpecialLocaleCode = (country: any, language: any): any => {
  
  if (language === "Dutch") {
    if (country === "Netherlands") {
      return "nl-BE";
    }
    return "be-NL";
  }

  if (language === "French") {
    if (country === "France") {
      return "fr-BE";
    }
    if (country === "Switzerland") {
      return "sz-FR";
    }
    return "be-FR";
  }

  if (language === "German") {
    if (country === "Austria") {
      return "at-DE";
    }
    if (country === "Switzerland") {
      return "sz-DE";
    }
    return "de-DE";
  }

  return undefined;
};
