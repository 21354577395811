import httpService from "../../data-services/HTTPService";
import ILoginModel, {
  IChangeEmailModel,
  IChangePasswordModel,
  IGetResetEmailModel,
  IRegisterModel,
  IResendRegisterModel,
  IResetPasswordModel,
} from "../../models/AuthModel";
import { IAccessResponse, IAuthResponse } from "../../models/ResponseModel";
import { mapFromLoginResponseToIUserModel } from "./AuthMapper";
import { AxiosResponse, AxiosError } from "axios";
import { IUserUpdate } from "../../models/User";

const baseUrl = "/Account";

export const access = async (password: string, browserLanguage: string): Promise<IAccessResponse> => {
  try {
    const url = `${baseUrl}/Access?password=${password}&browserLanguage=${browserLanguage}`;
    const response: AxiosResponse = await httpService.sendMessage(
      "get",
      url
    );

    if (response.status === 200 || response.status === 204) {
      return { status: "success" };
    } else if (response.status === 400) {
      return { status: "error", message: "Bad Request" };
    } else {
      return { status: "error", message: "Test error bad request" };
    }
  } catch (error) {
    return { status: "error", message: "Error" };
  }
};

export const login = async (data: ILoginModel): Promise<IAuthResponse> => {
  try {
    const url = `${baseUrl}/Login`;
    const response: AxiosResponse = await httpService.sendMessage(
      "post",
      url,
      data
    );
    if (response.status === 200) {
      return {
        status: "success",
        data: mapFromLoginResponseToIUserModel(response.data),
      };
    } else if (response.status === 400) {
      return { status: "error", message: "Bad Request" };
    } else {
      return { status: "error", message: "Test error bad request" };
    }
  } catch (error) {
    return {
      status: "error",
      statusCode: error.response.status,
      message: error.response.data.messages
    };
  }
};

export const register = async (
  data: IRegisterModel
): Promise<IAuthResponse> => {
  try {
    const url = `${baseUrl}/Register`;
    const response = await httpService.sendMessage("post", url, data);

    if (response.status === 200) {
      return { status: "success" };
    }

    return { status: "error" };
  } catch (error) {
    if (error.response.data.messages === "Email already in use." || error.response.data.message === "Email already in use.") {
      return { status: "error_emailInUse", message: "Email already in use." };
    }

    if (error.response.data.messages === "Invalid recaptcha.") {
      return { status: "error_invalidRecaptcha", message: "Invalid recaptcha." };
    }

    return { status: "error", message: "Error" };
  }
};

export const resendRegisterEmail = async (
  data: IResendRegisterModel
): Promise<IAuthResponse> => {
  try {
    const url = `${baseUrl}/Register?isResend=true`;
    const response = await httpService.sendMessage("post", url, data);

    if (response.status === 200) {
      return { status: "success" };
    }

    return { status: "error" };
  } catch (error) {
    return { status: "error", message: "Error" };
  }
};

export const updateUserData = async (
  data: IUserUpdate
): Promise<IAuthResponse> => {
  try {
    const url = `${baseUrl}/UpdateUserData`;
    const response = await httpService.sendMessage("put", url, data);
    if (response.status === 200) {
      return { status: "success" };
    } else if (response.status === 401) {
      return { status: "error", message: "Test error not auth" };
    } else {
      return { status: "error", message: "Test error bad request" };
    }
  } catch (error) {
    return { status: "error", message: "Test error bad request" };
  }
};

export const sendResetPasswordLink = async (
  data: IGetResetEmailModel
): Promise<any> => {
  try {
    const url = `${baseUrl}/SendResetPasswordLink`;
    await httpService.sendMessage("post", url, data);
  } catch (error) {
  } finally {
    return;
  }
};

export const resetPassword = async (
  data: IResetPasswordModel
): Promise<IAuthResponse> => {
  try {
    const url = `${baseUrl}/ResetPassword`;
    const response = await httpService.sendMessage("post", url, data);
    if (response.status === 200) {
      return { status: "success" };
    }
    return { status: "error" };
  } catch (error) {
    debugger;
    if(error.response)
    {
      switch (error.response.status) {
        case 400:
          return { status: "error", message: error.response.data.developerMessage || "The link has expired" };
        case 401:
          return { status: "error", message: "Unauthorized" };
        case 404:
          return { status: "error", message: "The link has expired or was an error" };
      }
    }
    return { status: "error", message: "An unknown error has occured. Please retry and if the problem persists contact support " };
  }
};

export const changePassword = async (
  data: IChangePasswordModel
): Promise<IAuthResponse> => {
  try {
    const url = `${baseUrl}/ChangePassword`;
    const response = await httpService.sendMessage("post", url, data);
    if (response.status === 200) {
      return { status: "success", statusCode: response.status };
    }
    return { status: "error", statusCode: response.status };
  } catch (error) {
    return { status: "error", message: "Error", statusCode: error.response.status };
  }
};

export const changeEmail = async (
  data: IChangeEmailModel
): Promise<IAuthResponse> => {
  try {
    const url = `${baseUrl}/SendResetEmailLink`;
    const response = await httpService.sendMessage("post", url, data);
    if (response.status === 200) {
      return { status: "success" };
    }
    return { status: "error" };
  } catch (error) {
    return { status: "error", message: "Error" };
  }
};
