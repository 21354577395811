import { makeStyles, createStyles } from "@material-ui/core/styles";
import colors from "../../common/styles/colors";
import { mainHeader, commonParagraph } from "../../common/styles/typography";

const useStyles = makeStyles((theme) =>
  createStyles({
    homeLogedInWrapper: {
      width: "100%",
      justifyContent: "space-between",
      //paddingTop: "67px",
      //paddingBottom: "140px",
    },
    homeLogedInItem: {
      //width: "50%",
      maxWidth: "50%",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
      //paddingLeft: "120px",
      "& img": {
        marginBottom: "-6px",
        width: "100%",
        //width: "800px",
        [theme.breakpoints.down("sm")]: {
          display: "none",
        }
        
      },
    },
    homeLogedInContent: {
      width: "50%",
      padding: "100px 120px 0 15px",

      "&:last(p)": {
        paddingBottom: "0",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        padding: "5%",
      },
    },
    title: {
      paddingTop: "17px",
      paddingBottom: "64px",
      ...mainHeader,
    },
    paragraph: {
      ...commonParagraph,
      color: colors.darkerGreyColor,
      paddingBottom: "30px",
      "&:last-of-type": {
        paddingBottom: 0,
      },
    },
    list: {
      "& .MuiListItem-gutters": {
        padding: 0,
      },
      "& .MuiListItemText-root": {
        marginTop: 0,
        marginBottom: 0,
      },
      "&.MuiList-padding": {
        paddingTop: 0,
      },
    },
    itemList: {
      minWidth: "15px",
    },
    listItemText: {
      "& span": {
        ...commonParagraph,
        color: colors.darkerGreyColor,
      },
      "& .MuiListItem-gutters": {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  })
);

export default useStyles;
