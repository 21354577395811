import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    buttonContainer: {
      width: "352px",
    },
    contentWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    content: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(6),
    },
    image: {
      width: "40px",
      paddingBottom: "42px",
    },
  })
);

export default useStyles;
