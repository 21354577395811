import React from "react";
import MaskedInput from "react-text-mask";

interface TextMask {
  inputRef: (ref: HTMLInputElement | null) => void;
}

const TextMaskCustom: React.FC<TextMask> = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[0-9a-fA-F]/,
        /[0-9a-fA-F]/,
        ":",
        /[0-9a-fA-F]/,
        /[0-9a-fA-F]/,
        ":",
        /[0-9a-fA-F]/,
        /[0-9a-fA-F]/,
      ]}
      placeholder="XX:XX:XX"
      placeholderChar={"\u2000"}
      showMask={false}
      guide={false}
      style={{width: "80px"}}
    />
  );
};

export default TextMaskCustom;
