import { makeStyles, createStyles } from "@material-ui/core/styles";
import colors from "../styles/colors";

const useStyles = makeStyles(() =>
  createStyles({
    select: {
      fontSize: "16px",
      lineHeight: "24px",
      fontFamily: "Roboto, sans-serif",
      backgroundColor: colors.veryLightGreyColor,
      borderRadius: "24px",
      width: "100%",
      "& span": {
        color: colors.primaryColor,
        textTransform: "none",
      },
      '& fieldset[class*="PrivateNotchedOutline-root-"]': {
        "&.MuiOutlinedInput-notchedOutline": {
          borderWidth: "0",
        },
      },
      "& .MuiSelect-outlined.MuiSelect-outlined": {
        padding: "12px 56px 12px 24px",
      },
      "& img": {
        width: "30px",
        height: "20px",
      },
      "& .MuiList-padding": {
        paddingTop: "0",
        paddingBottom: 0,
      },
      "& .MuiSelect-select:focus": {
        borderRadius: "24px",
      },
    },
    dropdownList: {
      boxShadow: "none",
      borderRadius: 0,
      "& ul": {
        paddingTop: 0,
        paddingBottom: 0,
      },
      maxHeight: "300px",
      overflowY: "scroll",
    },
    popupSelect: {
      backgroundColor: colors.veryLightGreyColor,
      "&:hover": {
        backgroundColor: colors.lightGreyColor,
      },
      "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
        backgroundColor: colors.lightGreyColor,
      },
    },
  })
);

export default useStyles;
