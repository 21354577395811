import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Button } from "@material-ui/core";
import useTypographyStyles from "../styles/typography";
import useButtonStyles from "../styles/buttonStyles";
import useStyles from "./DeleteModal.style";

interface IDelete {
  title: string;
  content: string;
  deleteButtonName: string;
  remove(): void;
  closeModal(): void;
}

const DeleteModal: React.FC<IDelete> = (props) => {
  const classes = useStyles();
  const typographyClasses = useTypographyStyles();
  const buttonClasses = useButtonStyles();
  const { title, content, remove, closeModal, deleteButtonName } = props;
  const { t } = useTranslation(["translation"]);

  return (
    <>
      <Grid item>
        <Typography
          align="center"
          component="h3"
          className={typographyClasses.headerThreeBlack}
        >
          {title}
        </Typography>
        <Typography className={classes.modalParagraph}>{content}</Typography>
      </Grid>
      <Grid item className={classes.buttonsContainer}>
        <Button
          className={buttonClasses.lightButtonWithBorderLargeWidth}
          fullWidth={true}
          onClick={closeModal}
        >
          {t("translation:common.cancel")}
        </Button>
      </Grid>
      <Grid item className={classes.buttonsContainer}>
        <Button
          className={buttonClasses.primaryButtonLargeWidth}
          fullWidth={true}
          onClick={remove}
        >
          {deleteButtonName}
        </Button>
      </Grid>
    </>
  );
};

export default DeleteModal;
