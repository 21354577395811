import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Typography, Popover, Button } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useAuth } from "../../../context/context";
import { AppRoute } from "../../../common/constants/routes";
import { getLocalizedRoute } from "../../../common/services/i18n/utils/routeLocalization";
import { useTranslation } from "react-i18next";
import useStyles from "./MyAccount.style";

interface IMyAccount {
  hideInstallerBar?(): void;
}

const MyAccount: React.FC<IMyAccount> = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { formatMessage, locale } = useIntl();
  const authStore = useAuth();
  const history = useHistory();
  const { t } = useTranslation(["translation"]);

  const { hideInstallerBar } = props;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (authStore?.isLoggedIn) {
      setAnchorEl(event.currentTarget);
    } else {
      history.push(getLocalizedRoute(AppRoute.Home, locale, formatMessage));
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const resetInstallerView = () => {
    authStore?.setImpersonatedUserHistory(null);
    authStore?.setImpersonatedUserEmail(null);

    localStorage.setItem("toImpersonateUserEmail", "null");

    if (hideInstallerBar) {
      hideInstallerBar();
    }
  }

  const logout = () => {
    authStore?.setAuthTokens();
    authStore?.removeItems();
    authStore?.setIsLogged(false);
    authStore?.setIsSuperUser(false);

    resetInstallerView();

    history.push(getLocalizedRoute(AppRoute.Home, locale, formatMessage));
    handleClose();
  };

  const redirectToMyAccount = () => {
    history.push(getLocalizedRoute(AppRoute.MyAccount, locale, formatMessage));
    handleClose();
  };

  return (
    <>
      <Button
          aria-describedby={id}
          onClick={handleClick}
          className={classes.userName}
      >
        <img
            src={`/img/${
                authStore?.isLoggedIn ? "LogedInIcon" : "ProfileSVG"
            }.svg`}
            alt="person"
        />
        <Typography component="span" className={classes.myAccountName}>
          {authStore?.isLoggedIn
              ? authStore.userHistory?.name
              : t("translation:common.loginLabel")}
        </Typography>
      </Button>
      <Popover
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: -16,
          horizontal: "center",
        }}
        className={classes.dropdown}
      >
        <Typography
          onClick={redirectToMyAccount}
          className={classes.dropdownItem}
        >
          {t("translation:menu.myAccount")}
        </Typography>
        <Typography onClick={logout} className={classes.dropdownItem}>
          {t("translation:menu.logout")}
        </Typography>
      </Popover>
    </>
  );
};

export default MyAccount;
