import React from "react";
import {useTranslation} from "react-i18next";
import {Grid, Button} from "@material-ui/core";
import useStyles from "./InstallerView.style";
import useButtonStyles from "../../common/styles/buttonStyles";
import {getLocalizedRoute} from "../../common/services/i18n/utils/routeLocalization";
import {AppRoute} from "../../common/constants/routes";
import {useAuth} from "../../context/context";
import {useHistory} from "react-router-dom";
import {useIntl} from "react-intl";
import {languageList} from "../../common/constants/countries";
import InstallerIcon from "./InstallerIcon";
import InstallerCertifiedIcon from "./InstallerCertifiedIcon";

const InstallerViewLoggedIn: React.FC = () => {
  const classes = useStyles();
  const buttonClasses = useButtonStyles();
  const {t} = useTranslation(["translation"]);
  const authStore = useAuth();
  let history = useHistory();
  const { formatMessage, locale } = useIntl();

  const installerTitle = t("translation:installerView.welcome").replace('[first name/CS]', '') + authStore?.userHistory.name ?? '';

  const getImpersonatedUserHistoryProperty = (property: string) : string => {
    const impersonatedUserHistory = authStore?.getImpersonatedUserHistory();
    if (impersonatedUserHistory === null) {
      return "-";
    }

    if (impersonatedUserHistory[property] !== undefined
         && impersonatedUserHistory[property] !== null) {
      if (property !== 'language') {
        return impersonatedUserHistory[property];
      } else {
        const language = languageList.find((l) => l.languageId === impersonatedUserHistory[property]);
        if (language !== undefined) {
          return language.name;
        }
        return 'en';
      }
    }

    return "-";
  }

  const deactivateInstallerView = () => {
    // authStore?.setAuthTokens();
    // authStore?.removeItems();
    // authStore?.setIsLogged(false);
    authStore?.setImpersonatedUserHistory(null);
    authStore?.setImpersonatedUserEmail(null);

    localStorage.setItem("toImpersonateUserEmail", "null");

    history.push(getLocalizedRoute(AppRoute.InstallerView, locale, formatMessage));
    window.location.reload();
  };

  return (
      <Grid container className={classes.homeContainer}>
        <Grid item className={classes.descriptionContainer}>

          <InstallerCertifiedIcon />

          <p className={classes.title}>
            {installerTitle}
          </p>

          <p className={classes.description}>
            {t("translation:installerView.firstDescription")}
          </p>

          <p className={classes.description}>
            {t("translation:installerView.secondDescription")}
          </p>

          <p className={classes.description}>
            {t("translation:installerView.thirdDescriptionTitle")}
            <br></br>
            {t("translation:installerView.thirdDescriptionFirstItem")}
            <br></br>
            {t("translation:installerView.thirdDescriptionSecondItem")}
            <br></br>
            {t("translation:installerView.thirdDescriptionThirdItem")}
          </p>

        </Grid>
        <Grid item className={classes.loginContainer}>
          <InstallerIcon />

          <p className={classes.titleRight}>
            {t("translation:installerView.name") + ' ' + getImpersonatedUserHistoryProperty("firstName") + ' ' + getImpersonatedUserHistoryProperty("lastName")}
          </p>

          <p className={classes.descriptionRightGrid}>
            {t("translation:installerView.loggedInDescription")}
            <br></br>
            <br></br>
            <br></br>
            +
            <br></br>
            <br></br>
            <br></br>
            {t("translation:installerView.loggedInInfo")}
            <br></br>
            {t("translation:installerView.loggedInFirstName") + ' ' + getImpersonatedUserHistoryProperty("firstName")}
            <br></br>
            {t("translation:installerView.loggedInLastName") + ' ' + getImpersonatedUserHistoryProperty("lastName")}
            <br></br>
            {t("translation:installerView.loggedInCountry") + ' ' + getImpersonatedUserHistoryProperty("country")}
            <br></br>
            {t("translation:installerView.loggedInLanguage") + ' ' + getImpersonatedUserHistoryProperty("language")}
          </p>

          <Grid item className={classes.verticalSpacing}>
            <Button
                className={buttonClasses.primaryButton}
                style={{width: "40%", marginBottom: "30px"}}
                fullWidth={true}
                onClick={deactivateInstallerView}
            >
              {t("translation:installerView.deactivateInstallerView")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
  );
};

export default InstallerViewLoggedIn;
