import GetSensorDescription from "../SensorData/GetSensorDescription";
import GetDefaultDescription from './GetDefaultDescription';
import * as Excel from "exceljs";
import { saveAs } from "file-saver";
import i18n from "i18next";
import { IDetectorModel } from "../../../models/DetectorModel";
import { IProject } from "../../../models/Project";
import { ISensorSetting } from "../../../models/SensorSetting";
import {IReceivedTemplateModel, ITemplateModel} from "../../../models/TemplatesModel";
import {sortLightSceneGroupsByPercentage} from "./ExportLightSceneHelper";
import moment from "moment";


const title_style = {
  name: "Calibri",
  size: 12,
  bold: true,
  color: { argb: "0D0D0D" }
};

const title_fill = {
  type: "pattern",
  pattern: "solid",
  fgColor: { argb: "BFBFBF" },
};

const h1 = {
  name: "Calibri",
  size: 12,
  bold: true,
  color: { argb: "404040" } 
};

const h2 = {
  name: "Calibri",
  size: 12,
  bold: true,
  color: { argb: "404040" }
};

const h3 = {
  name: "Calibri",
  size: 12,
  color: { argb: "404040" }
};

const value_style = {
  name: "Calibri",
  size: 11,
  color: { argb: "404040" }
}

const value_bold = {
  name: "Calibri",
  size: 11,
  bold: true,
  color: { argb: "404040" }
}


const DetectorsInfoMethods = {
  addSubtab: (dataMatrix:any, currentTab:any, currentSubTab:any, item:any, i:any):any => {
      dataMatrix[`${currentTab}`][`${item.type}`] = {};
      currentSubTab = item.type;
      if(item.value) {
        let arr = [];
        arr[i] = item;
        dataMatrix[`${currentTab}`][`${currentSubTab}`][`${item.type}`] = {
          type: item.type,
          style: item.style,
          label: item.label,
          btnName: item.btnName ?? "",
          orderId: (item.orderId) ? item.orderId : "",
          values: arr
        }
      }
      return [dataMatrix, currentSubTab]
  },
  addSubtabValue: (dataMatrix:any, currentTab:any, currentSubTab:any, item:any, i:any) => {
      currentSubTab = item.type;
      if(item.value) dataMatrix[`${currentTab}`][`${currentSubTab}`][`${item.type}`].values[i] = item;
    
      return [dataMatrix, currentSubTab]
  }, 
  addProperty: (dataMatrix:any, currentTab:any, currentSubTab:any, item:any, i:any):any => {
      let arr = [];
      arr[i] = item;
      dataMatrix[`${currentTab}`][`${currentSubTab}`][`${item.type}`] = {
        type: item.type,
        style: item.style,
        label: item.label,
        btnName: item.btnName ?? "",
        orderId: (item.orderId) ? item.orderId : "",
        values: arr
      }
      return [dataMatrix]
  },
  addPropertyValue: (dataMatrix:any, currentTab:any, currentSubTab:any, item:any, i:any):any => {
      dataMatrix[`${currentTab}`][`${currentSubTab}`][`${item.type}`].values[i] = item;
      return [dataMatrix]
  },
  addTranslatedValues: (item:any, currentTab:any) => {
      if(item.name?.toLowerCase().includes("broadcast")) item.value = ["Broadcast"]   
      if(item.name?.toLowerCase().includes("hvac")) item.value = ["HVAC"]
      if(item.name?.toLowerCase().includes("lighting") && item.type.toLowerCase().includes("zone")) item.value = ["Lighting"] 

      if(item.type == "zones" && currentTab == "settings") item.label = `${i18n.t(`translation:SensorData.pushButtons.zones`)}/${i18n.t(`translation:SensorData.channels.channels`)}:`;
      if(item.type == "zone1" && currentTab == "settings") item.label = `${i18n.t(`translation:SensorData.fineTunning.zone`)}/${i18n.t(`translation:SensorData.channels.channel`)} 1`;
      if(item.type == "zone2" && currentTab == "settings") item.label = `${i18n.t(`translation:SensorData.fineTunning.zone`)}/${i18n.t(`translation:SensorData.channels.channel`)} 2`;
      if(item.type == "zone3" && currentTab == "settings") item.label = `${i18n.t(`translation:SensorData.fineTunning.zone`)} 3`;
      if(item.type == "zone4" && currentTab == "settings") item.label = `${i18n.t(`translation:SensorData.fineTunning.zone`)} 4`;
  },
  setSettingsDisplayOrder: (dataMatrix:any) => {
    if(dataMatrix.settings){
      if(dataMatrix.settings["zone4"]) dataMatrix.settings = {"zone4": dataMatrix.settings["zone4"], ...dataMatrix.settings};
      if(dataMatrix.settings["zone3"]) dataMatrix.settings = {"zone3": dataMatrix.settings["zone3"], ...dataMatrix.settings};
      if(dataMatrix.settings["zone2"]) dataMatrix.settings = {"zone2": dataMatrix.settings["zone2"], ...dataMatrix.settings};
      if(dataMatrix.settings["zone1"]) dataMatrix.settings = {"zone1": dataMatrix.settings["zone1"], ...dataMatrix.settings};
      if(dataMatrix.settings["zones"]) dataMatrix.settings = {"zones": dataMatrix.settings["zones"], ...dataMatrix.settings};
      dataMatrix = {"settings": dataMatrix.settings, ...dataMatrix}
    } 
    return dataMatrix;
  },
  setFunctionsDisplayOrder: (dataMatrix:any) => {
    if(dataMatrix.functions){
      if(dataMatrix.functions["activeCurve"]) dataMatrix.functions = {"activeCurve": dataMatrix.functions["activeCurve"], ...dataMatrix.functions};
      if(dataMatrix.functions["tunableWhite"]) dataMatrix.functions = {"tunableWhite": dataMatrix.functions["tunableWhite"], ...dataMatrix.functions};
    }  
    return dataMatrix;
  },
  setButtonsDisplayOrder: (dataMatrix:any) => {
    let pressAndHoldTiming = (dataMatrix.pushButtons && dataMatrix.pushButtons.pushButtonOperation) 
                            ? dataMatrix.pushButtons.pushButtonOperation
                            : null

    const returnBtnName = (btnCounter:number, i:number) => {
      switch(btnCounter){
        case 1:
          return `wiredButtonsTitle`
        case 2:
          return `230 V inputs T${i}, Push button (1 action)`
        case 3:
          return `230 V inputs T${i}, Switch (2 actions)`
        case 4: 
          return `blePushButtonTitle`
        case 5:
          return `blePushButton${i}`
        case 6:
          return `daliPushButtonsTitle`
        case 7:
          return `daliPushButton${i}`
        default:
          return `stop`
      }
    }

    if(dataMatrix.pushButtons){
      let orderedArr:any = []
      for(let i=1; i<=8; i++){
          for(let j=1; j<=5; j++){
            let currentButton = returnBtnName(i, j)
            Object.entries(dataMatrix.pushButtons).map((prop:any)=>{
                let [property, value] = prop;
                if(value[property].type == currentButton) orderedArr.push(prop)
            })
          }
      }

      let orderedPushButtons:any = {}
      orderedArr.forEach((prop:any)=>{
        let [property, value] = prop;
        orderedPushButtons[property] = value;
      })

      //DELETE TITLES 
      if(!orderedPushButtons.blePushButton1) delete orderedPushButtons.blePushButtonTitle
      if(!orderedPushButtons.daliPushButton1) delete orderedPushButtons.daliPushButtonsTitle


      dataMatrix.pushButtons = orderedPushButtons;
      if(pressAndHoldTiming) dataMatrix.pushButtons.pushButtonOperation = pressAndHoldTiming;
    }

    return dataMatrix;
  }
}




const generateDetectorsInfo = (
    detectors: any,
    isDetectorSetting: boolean
  ): any => {
    let detectorList = detectors.map((detector:any)=> GetDefaultDescription(detector, isDetectorSetting))  
    let dataMatrix:any = {}

    console.log("Detectors JSON", detectors)
    console.log("Detectors", detectorList)

    detectorList.forEach((detector:any, i:number) => {
      if(detector !== undefined) {

        sortLightSceneGroupsByPercentage(detector);

        Object.entries(detector).forEach((tab: any) => {
          let [ currentTab, tabValues ] = tab; 
          if(currentTab === "channels") currentTab = "settings";

          if (currentTab !== "tabs") {
            if(!dataMatrix[`${currentTab}`]) dataMatrix[`${currentTab}`] = {}
            let currentSubtab = {};

            tabValues.forEach((item: any) => {
              DetectorsInfoMethods.addTranslatedValues(item, currentTab)

              if(item.style === "h1" || item.style === "h2") {
                if(dataMatrix[`${currentTab}`][`${item.type}`]) {
                  [dataMatrix, currentSubtab] = DetectorsInfoMethods.addSubtabValue(dataMatrix, currentTab, currentSubtab, item, i)
                } else {
                  [dataMatrix, currentSubtab] = DetectorsInfoMethods.addSubtab(dataMatrix, currentTab, currentSubtab, item, i)
                }
              }

              if(item.style === "h3") {
                if(dataMatrix[`${currentTab}`][`${currentSubtab}`][`${item.type}`]){
                  [dataMatrix] = DetectorsInfoMethods.addPropertyValue(dataMatrix, currentTab, currentSubtab, item, i)
                }else{
                  [dataMatrix] = DetectorsInfoMethods.addProperty(dataMatrix, currentTab, currentSubtab, item, i)
                }
              }
            })
          }
        })
      }
    })

    dataMatrix = DetectorsInfoMethods.setSettingsDisplayOrder(dataMatrix);
    dataMatrix = DetectorsInfoMethods.setFunctionsDisplayOrder(dataMatrix);
    dataMatrix = DetectorsInfoMethods.setButtonsDisplayOrder(dataMatrix);

    console.log("dataMatrix: ", dataMatrix);
    return dataMatrix;
}








const generateWorksheet = (
  worksheet: any,
  detectors: any, 
  isDetectorSetting: boolean
): void => {
  let dataMatrix = generateDetectorsInfo(detectors, isDetectorSetting);

  //TO DO: Support 3-letters columns 
  const getColumn = (i:number) => {
    const letter = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
    
    let col = [];

    let k = 0;
    let currentLetter = 'A'
    while(k <= i){
      currentLetter = letter[k%letter.length]
      if(k%letter.length == 0 && k != 0) col[0]=letter[k/letter.length-1]
      k++
    }
    col.push(currentLetter)

    return col.join("");
  }

  let detectorCounter = detectors.length;
  let detailsRow = 7;

   //SET WIDTH
   worksheet.getColumn("B").width = 60;
   for(let i=0; i<=detectorCounter; i++) worksheet.getColumn(`${getColumn(i+2)}`).width = 30;
 
   //TABLE TITLES
   worksheet.getCell('B3').value = `${i18n.t(`translation:SensorData.export.detectorType`)}`;
   worksheet.getCell('B4').value = `${i18n.t(`translation:SensorData.export.productNumber`)}`;
    worksheet.getCell('B5').value = `${i18n.t(`translation:SensorData.export.detectorName`)}`;
   worksheet.getCell('B6').value = `${i18n.t(`translation:SensorData.export.macAddress`)}`;
   worksheet.getCell('B7').value = `${i18n.t(`translation:SensorData.export.projectName`)}`;
   
   let isReceivedTemplate = !isDetectorSetting && "receivedFrom" in detectors[0];
   if (isReceivedTemplate)
   {
     worksheet.getCell('B8').value = `${i18n.t(`translation:Templates_Receivedtemplates.tableHeadings.receivedFrom`)}`;
     worksheet.getCell('B9').value = `${i18n.t(`translation:Templates_Receivedtemplates.tableHeadings.receivedDate`)}`;
     detailsRow = 9;
   }
 
   for(let i=3; i<detailsRow; i++){
     worksheet.getCell(`B${i}`).font = title_style;
     worksheet.getCell(`B${i}`).fill = title_fill;
   }

   let row = detailsRow;
   //TITLE VALUES
   detectors.forEach((detector: any, i:number) => {
       worksheet.getCell(`${getColumn(i+2)}${3}`).value = detector.sensorInformation.detectorShortDescription;
       worksheet.getCell(`${getColumn(i+2)}${4}`).value = detector.sensorInformation.name;
       worksheet.getCell(`${getColumn(i+2)}${5}`).value = detector.customName ?? '-';
       worksheet.getCell(`${getColumn(i+2)}${6}`).value = detector.macAddress?.split("").map((num:any, i:number) => (i%2==0 && i!=0) ? `:${num}` : num).join("") ?? detector.templateName;
       worksheet.getCell(`${getColumn(i+2)}${7}`).value = detector.projectName;
       worksheet.getCell(`${getColumn(i+2)}${3}`).font = title_style;
       worksheet.getCell(`${getColumn(i+2)}${4}`).font = title_style;
       worksheet.getCell(`${getColumn(i+2)}${5}`).font = title_style;
       worksheet.getCell(`${getColumn(i+2)}${6}`).font = title_style;
       worksheet.getCell(`${getColumn(i+2)}${7}`).font = title_style;
       if (isReceivedTemplate)
       {
         worksheet.getCell(`${getColumn(i+2)}${7}`).value = detector.receivedFrom;
         worksheet.getCell(`${getColumn(i+2)}${8}`).value = `${moment.tz(detector.receivedOn, moment.tz.guess()).format("DD/MM/YYYY")}`;
         worksheet.getCell(`${getColumn(i+2)}${7}`).font = title_style;
         worksheet.getCell(`${getColumn(i+2)}${8}`).font = title_style;
       }
   })

  //FROZEN COLUMN & LINE
  worksheet.views = [ {state: 'frozen', xSplit: 2, ySplit: 6, activeCell: 'A1'} ];


  Object.entries(dataMatrix).forEach((tab:any) => {
      const [tabName, properties] = tab;
      let tabRow = row; // tab name will be added after proprieties (only if we have some data)   
      row++

      // console.log("TabName: ", tabName)
      // console.log("Properties: ", properties)
      Object.entries(properties).forEach((subtab:any)=>{
          const [subtabName, subtabValues] = subtab;
          if(subtabValues)
          Object.entries(subtabValues).forEach((property:any)=>{
              const [ propertyName, keys] = property;
              // console.log("TabName: ", propertyName)
              // console.log("Properties: ", keys)

              if(property[1].style === "h1" || property[1].style === "h2"){
                worksheet.getCell(`B${row}`).value = keys.label;
                worksheet.getCell(`B${row}`).font = h1;
                worksheet.getCell(`B${row}`).alignment = { wrapText: true, vertical: 'top' };
              }else{
                worksheet.getCell(`B${row}`).value = keys.label;
                worksheet.getCell(`B${row}`).font = h3;
                worksheet.getCell(`B${row}`).alignment = { wrapText: true, vertical: 'top' };

                //EDGE CASE
                if(keys.type && keys.type.includes("TunableWhitePresetName")) worksheet.getCell(`B${row}`).font = h1;
              }

              if(keys != undefined)  
              keys.values.forEach((value:any, c:number) => {
                let cell = `${getColumn(c+2)}${row}`;
                let currentValue = value.value.join(', ');

                //Light scene format
                if((currentValue.includes("Group") || currentValue.includes("in use")) && currentValue.includes("luminaire")){
                  currentValue = ''
                  value.value.forEach((val:any)=> currentValue = currentValue + val + ' '.repeat(60-val.length))
                }

                worksheet.getCell(cell).value = currentValue;
                worksheet.getCell(cell).alignment = { wrapText: true, vertical: 'top' };
                (value.isDefault) ? worksheet.getCell(cell).font = value_style
                                  : worksheet.getCell(cell).font = value_bold
              })
              row++
          })
      })

      // add the tab name (e.g. communication) if it has some proprieties
      if (row != tabRow+1)
      {
        worksheet.getCell(`B${tabRow}`).value = i18n.t(`translation:SensorData.tabs.${tabName}Tab`)
        worksheet.getCell(`B${tabRow}`).font = title_style;
        for(let i=1; i<detectorCounter+2; i++) worksheet.getCell(`${getColumn(i)}${tabRow}`).fill = title_fill;
      }
  })

  //OUTSIDE BORDER
  row--
  for(let i=3; i<row; i++) {
    worksheet.getCell(`B${i}`).border = { left: {style:'thin'} };
    worksheet.getCell(`${getColumn(detectorCounter+1)}${i}`).border = { right: {style:'thin'} };
  }
  for(let i=1; i<=detectorCounter+1; i++){
    if(i==1) {
      worksheet.getCell(`${getColumn(i)}3`).border = { top: {style:'thin'}, left: {style:'thin'} };
      worksheet.getCell(`${getColumn(i)}${row}`).border = { bottom: {style:'thin'}, left: {style:'thin'} };
    } else if(i==detectorCounter+1){
      worksheet.getCell(`${getColumn(i)}3`).border = { top: {style:'thin'}, right: {style:'thin'} };
      worksheet.getCell(`${getColumn(i)}${row}`).border = { bottom: {style:'thin'}, right: {style:'thin'} };
    } else {
      worksheet.getCell(`${getColumn(i)}3`).border = { top: {style:'thin'} };
      worksheet.getCell(`${getColumn(i)}${row}`).border = { bottom: {style:'thin'} };
    }
  }

  //HIDE PROJECT NAME ROW (IF NOT USED)
  let showProjectName = detectors.some((detector:any) => {
    return detector.projectName != undefined
  })
  if(!showProjectName) {
    worksheet.getRow(5).hidden = true;
    worksheet.getRow(6).hidden = true;
  }
}










const saveExcel = async (workbook: any, title: string) => {
  const buf = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buf]), `${title}.xlsx`);
};


export const exportDetectorsExcel = async (
  detectors: IDetectorModel[] | ITemplateModel[] | IReceivedTemplateModel[] | ISensorSetting[],
  isDetectorSetting: boolean,
  title?: string,
) => {
  const workbook = new Excel.Workbook();

  let sheetName = (title) ? title : detectors[0].sensorInformation.name
  let worksheet = workbook.addWorksheet((sheetName) ? sheetName : `${i18n.t(`translation:SensorData.export.detector`)}`)
  generateWorksheet(worksheet, detectors, isDetectorSetting);

  //Download
  if (title) saveExcel(workbook, `${title}`);
  else if (detectors.length === 1) saveExcel(workbook, `${detectors[0].sensorInformation.name}`);
  else saveExcel(workbook, `${i18n.t(`translation:SensorData.export.detectors`)}`);
};


export const exportProjectsExcel = async (projects: IProject[]) => {
  const workbook = new Excel.Workbook();
  let worksheet = <any>[];

  if (projects.length == 1) {
    exportDetectorsExcel(projects[0].sensorSettings, true, projects[0].name);
  } else {
    projects.forEach((project: IProject, i: number) => {
      worksheet[i] = workbook.addWorksheet(
        `${i + 1}-${
          project.name.length > 25 ? project.name.slice(0, 25) : project.name
        }`.replace("/", ".")
      ); //Sheet name must have less than 31 chars and doesn't support '/'
      generateWorksheet(worksheet[i], project.sensorSettings, true);
    });

    if (projects.length === 1) saveExcel(workbook, `${projects[0].name}`);
    else saveExcel(workbook, `${i18n.t(`translation:SensorData.export.projects`)}`);
  }
};
