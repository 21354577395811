import { makeStyles, createStyles } from "@material-ui/core/styles";
import colors from "../styles/colors";
import { commonParagraph } from "../styles/typography";

const useStyles = makeStyles((theme) =>
  createStyles({
    modalParagraph: {
      marginBottom: theme.spacing(6),
      paddingTop: theme.spacing(1),
      color: colors.darkerGreyColor,
      textAlign: "center",
      ...commonParagraph,
    },
    buttonsContainer: {
      display: "flex",
      "& button": {
        width: "160px",
        height: "48px",
      },
      width: "80%",
      justifyContent: "space-around",
      marginBottom: theme.spacing(2),
    },
  })
);

export default useStyles;
