import React, {useState} from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useIntl } from "react-intl";
import {
  Grid,
  Card,
  CardContent,
  Button,
  Typography,
  CardMedia,
} from "@material-ui/core";
import Login from "../Auth/Login/Login";
import { AppRoute } from "../../common/constants/routes";
import { getLocalizedRoute } from "../../common/services/i18n/utils/routeLocalization";
import useStyles from "./Home.style";
import useButtonStyles from "../../common/styles/buttonStyles";

const Home: React.FC = () => {
  let history = useHistory();
  const classes = useStyles();
  const buttonClasses = useButtonStyles();
  const { formatMessage, locale } = useIntl();
  const { t } = useTranslation(["translation"]);

  let globalAccess = localStorage.getItem("globalAccess") ?? "";
  //const [hasAccessToPlatform] = useState(globalAccess === 'true');
  const hasAccessToPlatform = 'true';

  const handleClick = () => {
    history.push(getLocalizedRoute(AppRoute.About, locale, formatMessage));
  };

  return (
    <Grid container className={classes.homeContainer}>
      <Grid item className={classes.descriptionContainer}>
        <Card className={classes.descriptionCard}>
          <CardMedia
            component="img"
            alt="home page"
            height="480"
            image="/img/Detectortoolportal_width_big.jpg"
          />
          <CardContent className={classes.alignCenter}>
            <Typography component="h3" className={classes.title}>
              {t("translation:home.descriptionTitle")}
            </Typography>
            <Typography className={classes.description}>
              {t("translation:home.content")}
            </Typography>
            {
              hasAccessToPlatform
              &&
              <Button
                  className={`${buttonClasses.lightButtonWithBorder} ${classes.buttonSize}`}
                  onClick={handleClick}
              >
                { t("translation:home.descriptionButton")}
              </Button>
            }
          </CardContent>
        </Card>
      </Grid>
      <Grid item className={classes.loginContainer}>
        <Login
            maintenancePage={!hasAccessToPlatform}
        />
      </Grid>
    </Grid>
  );
};

export default Home;
