import { makeStyles, createStyles } from "@material-ui/core/styles";
import colors from "../../common/styles/colors";

const useStyles = makeStyles((theme) =>
  createStyles({
    footerContainer: {
      backgroundColor: colors.secondaryColor,
      mixBlendMode: "multiply",
      padding: "31px 120px 52px",
      justifyContent: "space-between",
      marginTop: "auto",
      [theme.breakpoints.down("sm")]: {
        padding: "30px 60px",
      },
    },
    footerTitle: {
      fontSize: "14px",
      lineHeight: "36px",
      color: colors.primaryColor,
      fontFamily: "humanistBD",
      borderBottom: `1px solid ${colors.pinkSwan}`,
      paddingBottom: "10px",
      marginBottom: "15px",
      maxWidth: "155px",
      minWidth: "max-content"
    },
    footerItem: {
      color: colors.darkerGreyColor,
      fontSize: "14px",
      fontFamily: "humanistRM",
      lineHeight: "30px",
      width: "300px",
      whiteSpace: "pre-line"
    },
    footerHiddenItem: {
      color: colors.secondaryColor,
      fontSize: "14px",
      fontFamily: "humanistRM",
      lineHeight: "30px",
      width: "300px",
    }
  })
);

export default useStyles;
