import httpService from "../HTTPService";

export const getImpersonatedUserData = async (email: string): Promise<any> => {
    try {
        const url = 'account/ImpersonatedUserData?impersonate=' + email;
        const { data, status } = await httpService.sendMessage("get", url);

        if (status === 200) {
            return {
                status: "success",
                data: data
            };
        }

        return { status: "error" };
    } catch (error) {
        return { status: "error" };
    }
};
