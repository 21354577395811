import colors from "./colors";
import {createStyles, makeStyles} from "@material-ui/core/styles";

const useNothingIsSelectedModalStyles = makeStyles((theme) =>
    createStyles({
        style: {
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "508px",
            height: "402px",
            paddingTop: theme.spacing(13),
            position: "absolute",
            backgroundColor: colors.secondaryColor,
            alignItems: "center",
        },
    })
);

export default useNothingIsSelectedModalStyles;
